export const stringAvatar = (name: string) => {
    if (!name) {
        return {
            children: "",
        };
    }
    if (name.split(" ").length > 1) {
        return {
            children: `${name.split(" ")[0][0]}${
                name.split(" ")[1][0]
            }`.toUpperCase(),
        };
    }
    return {
        children: `${name[0]}${name[1]}`.toUpperCase(),
    };
};
