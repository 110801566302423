export const getCookie = (name: string) => {
    const cookies = document.cookie;
    const prefix = name + "=";
    let begin = cookies.indexOf("; " + prefix);
    let end = 0;
    if (begin === -1) {
        begin = cookies.indexOf(prefix);
        if (begin !== 0) return null;
    } else {
        begin += 2;
        end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = cookies.length;
        }
    }
    return decodeURI(cookies.substring(begin + prefix.length, end));
};
