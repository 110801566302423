import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { px2rem } from "./px2rem";

export const muiDrawer: {
    defaultProps?: ComponentsProps["MuiDrawer"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiDrawer"];
    variants?: ComponentsVariants["MuiDrawer"];
} = {
    styleOverrides: {
        paper: {
            maxHeight: "85%",
        },
        paperAnchorBottom: {
            borderTopLeftRadius: px2rem(16),
            borderTopRightRadius: px2rem(16),
            paddingTop: px2rem(16),
            paddingBottom: px2rem(20),
        },
    },
};
