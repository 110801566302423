import React from "react";

const ArrowRightIcon = () => {
    return (
        <svg
            className="svg-inline"
            viewBox="0 0 10 8"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.5 4.11108H1.5"
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 4.11108L5.5 7.11108"
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 4.11108L5.5 1.11108"
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowRightIcon;
