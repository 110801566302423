import React, { Fragment } from "react";
import { Button } from "@mui/material";

const AcceptInviteExpired = () => {
    return (
        <Fragment>
            <div className={"flex-auto flex flex-col md:flex-none"}>
                <div className="text-center flex-auto">
                    <p className="text-24 font-bold mt-40 leading-6 md:mt-12 md:text-20">
                        Oops. That link is invalid.
                    </p>
                    <p className="text-primary-80 mt-12">
                        Something went wrong. Looks like the link you clicked on
                        has expired. You might need to ask the account owner to
                        send you a new invite.
                    </p>
                </div>
                <div className="mt-20">
                    <Button variant="contained" fullWidth={true}>
                        Log In
                    </Button>
                </div>
            </div>
            <div />
        </Fragment>
    );
};

export default AcceptInviteExpired;
