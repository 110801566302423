import React, { FC, useState } from "react";
import * as Yup from "yup";
import Dialog, { DialogProps } from "../../common/dialog/Dialog";
import FormContainer from "../../common/form/FormContainer";
import { LoadingButton } from "@mui/lab";
import { Button, Grid } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { useSnackbar } from "notistack";
import TextFormField from "../../common/form/TextFormField";

const passwordSchema = () =>
    Yup.object().shape({
        password: Yup.string()
            .required("Password is required.")
            .min(8, "Password must be at least 8 characters.")
            .max(20, "Password may not be greater than 20 characters."),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Password confirmation does not match."
        ),
    });

interface ChangePasswordModalProps extends Omit<DialogProps, "onSubmit"> {
    onSubmit: (newPassword: string) => void;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
    onClose,
    onSubmit,
    ...props
}) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async (values: FieldValues) => {
        setIsUpdating(true);
        try {
            await onSubmit(values.password);
        } catch (e) {
            enqueueSnackbar("Somethings went wrong!", { variant: "error" });
        }
        setIsUpdating(false);
    };
    return (
        <Dialog title={"Change Password"} {...props} onClose={onClose}>
            <FormContainer
                schema={passwordSchema()}
                onSubmit={handleSubmit}
                className="px-28"
            >
                <div>
                    <div className="mt-20">
                        <TextFormField
                            name="password"
                            type="password"
                            placeholder={"New Password"}
                            fullWidth
                        />
                    </div>
                </div>
                <div className="mb-28 mt-16">
                    <TextFormField
                        name="confirmPassword"
                        type="password"
                        placeholder={"Repeat New Password"}
                        fullWidth
                    />
                </div>
                <Grid container spacing={1.5}>
                    <Grid item xs={6}>
                        <Button variant="outlined" fullWidth onClick={onClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            fullWidth
                            loading={isUpdating}
                        >
                            Update
                        </LoadingButton>
                    </Grid>
                </Grid>
            </FormContainer>
        </Dialog>
    );
};

export default ChangePasswordModal;
