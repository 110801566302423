import * as Yup from "yup";

import React, { FC, useEffect, useState } from "react";
import Dialog, { DialogProps } from "../../common/dialog/Dialog";
import FormContainer from "../../common/form/FormContainer";
import { FieldValues } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextFormField from "../../common/form/TextFormField";
import { AxiosError } from "axios";

const changeEmailSchema = (email: string) =>
    Yup.object().shape({
        email: Yup.string()
            .required("Email is required.")
            .notOneOf([email], "Cannot use current email.")
            .email("Email field must be a valid email."),
    });

interface ChangeEmailModalProps extends Omit<DialogProps, "onSubmit"> {
    email: string;
    onClose: () => void;
    onSubmit: (email: string) => void;
}

const ChangeEmailModal: FC<ChangeEmailModalProps> = ({
    email,
    onClose,
    onSubmit,
    ...props
}) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    useEffect(() => {
        if (!props.open) {
            setMessage(null);
        }
    }, [props.open]);
    const handleSubmit = async (values: FieldValues) => {
        setIsUpdating(true);
        try {
            await onSubmit(values.email);
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                setMessage(e.response?.data.message);
            }
        }
        setIsUpdating(false);
    };
    return (
        <Dialog title={"Change Email"} {...props} onClose={onClose}>
            <FormContainer
                className="px-28"
                schema={changeEmailSchema(email)}
                onSubmit={handleSubmit}
            >
                <div className="mt-20">
                    <TextFormField
                        name="email"
                        helperText={message}
                        FormHelperTextProps={{ error: true }}
                        type="email"
                        placeholder={"New Email"}
                        fullWidth
                    />
                </div>
                <div className="mt-28">
                    <Grid container spacing={1.5}>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                fullWidth
                                loading={isUpdating}
                            >
                                Change
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </div>
            </FormContainer>
        </Dialog>
    );
};

export default ChangeEmailModal;
