import runes from "runes";

export const getFirstWord = (string: string, num = 1) => {
    if (typeof string === "undefined" || string === null) {
        return "";
    }

    const strArr = runes(string);
    return strArr.length > 0 ? strArr.slice(0, num).join("") : "";
};
