import React from "react";

function PasswordIcon() {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 15 16"
            fill="none"
            stroke={"currentColor"}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.1606 7.38672H2.33698C1.56191 7.38672 0.933594 8.01503 0.933594 8.7901V13.7019C0.933594 14.477 1.56191 15.1053 2.33698 15.1053H12.1606C12.9357 15.1053 13.564 14.477 13.564 13.7019V8.7901C13.564 8.01503 12.9357 7.38672 12.1606 7.38672Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.74023 7.38651V4.57974C3.74023 3.64924 4.10987 2.75685 4.76784 2.09889C5.4258 1.44093 6.31819 1.07129 7.24869 1.07129C8.17919 1.07129 9.07158 1.44093 9.72954 2.09889C10.3875 2.75685 10.7571 3.64924 10.7571 4.57974V7.38651"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default PasswordIcon;
