import React from "react";
import logoCore from "../assets/images/logo-core.svg";
import logoSite from "../assets/images/logo-site.svg";
import { getAppSource, SOURCE } from "../shared/utils/string/app-source";
import { useSearchParams } from "react-router-dom";

const AppLogo = () => {
    const [searchParams] = useSearchParams();
    const source = getAppSource(searchParams.get("redirect_uri"));
    return source === SOURCE.SITE ? (
        <img src={logoSite} alt="Menuzen Site" className="h-20 md:h-28" />
    ) : (
        <img src={logoCore} alt="Menuzen" className="h-20" />
    );
};

export default AppLogo;
