import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";

export const muiCard: {
    defaultProps?: ComponentsProps["MuiCard"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiCard"];
    variants?: ComponentsVariants["MuiCard"];
} = {
    styleOverrides: {
        root: {
            borderRadius: 8,
            overflowY: "auto",
        },
    },
};
