import React, { FC, Fragment } from "react";
import { useState } from "react";
import { webAuth } from "../../lib/auth";
import * as Yup from "yup";
import EmailIcon from "../../common/icon/EmailIcon";
import PasswordIcon from "../../common/icon/PasswordIcon";
import GoogleIcon from "../../common/icon/GoogleIcon";
import FacebookIcon from "../../common/icon/FacebookIcon";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { authApi } from "../../shared/services/auth/auth-api";
import { AxiosError } from "axios";
import { ZenImage } from "../../common/image";
import { getFirstWord } from "../../shared/utils/string/get-first-word";
import { useAsync } from "react-use";
import { inviteApi } from "../../shared/services/invite/invite-api";
import { InviteInfo } from "../../shared/types/response/invite-info";
import {
    getAppSource,
    getGetStartedUrl,
} from "../../shared/utils/string/app-source";
import { getRedirectUri } from "../../shared/utils/url/get-redirect-uri";
import FormContainer from "../../common/form/FormContainer";
import TextFormField from "../../common/form/TextFormField";
import { Button, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { px2rem } from "../../common/theme/px2rem";
import ArrowRightIcon from "../../common/icon/ArrowRightIcon";
import AgreeTerm from "../../components/AgreeTerm";
import { isSupportSNS } from "../../lib/sns";
import Loading from "../../components/loading/Loading";

interface SignUpFormValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export const authSchema = () =>
    Yup.object().shape({
        firstName: Yup.string()
            .max(50, "First name may not be greater than 50 characters.")
            .required("First name is required."),
        lastName: Yup.string()
            .max(50, "Last name may not be greater than 50 characters.")
            .required("Last name is required."),
        email: Yup.string()
            .required("Email is required.")
            .email("Email field must be a valid email."),
        password: Yup.string()
            .required("Password is required.")
            .min(8, "Password must be at least 8 characters.")
            .max(20, "Password may not be greater than 20 characters."),
    });

interface SignUpFormProps {
    onSignUpSuccess: ({
        email,
        token,
    }: {
        email: string;
        token: string;
    }) => void;
}

const SignUpForm: FC<SignUpFormProps> = ({ onSignUpSuccess }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isGettingInviteData, setIsGettingInviteData] = useState(false);
    const [inviteInfo, setInviteInfo] = useState<InviteInfo>();
    const [searchParams] = useSearchParams();
    const fromInvite = searchParams.get("fromInvite") as string;
    const redirectUri = searchParams.get("redirect_uri");

    const callbackUrl = () => {
        let url =
            window.location.protocol +
            "//" +
            window.location.host +
            process.env.REACT_APP_AUTH_CALLBACK;
        if (redirectUri) {
            url += `?redirect_uri=${getRedirectUri(redirectUri)}`;
        }
        return url;
    };

    useAsync(async () => {
        if (!inviteInfo && fromInvite) {
            setIsGettingInviteData(true);
            try {
                const res = await inviteApi.getInviteInfo(fromInvite);
                setInviteInfo(res.data);
            } catch (e) {}
            setIsGettingInviteData(false);
        }
    }, [inviteInfo, fromInvite]);

    const loginGoogle = () => {
        webAuth.authorize({
            redirectUri: callbackUrl(),
            connection: "google-oauth2",
        });
    };

    const loginFacebook = () => {
        webAuth.authorize({
            redirectUri: callbackUrl(),
            connection: "facebook",
        });
    };

    const onSignUp = async (values: SignUpFormValues) => {
        setIsLoading(true);
        setErrorMessage("");
        try {
            const res = await authApi.signUp(
                values.firstName,
                values.lastName,
                values.email,
                values.password,
                getAppSource(searchParams.get("redirect_uri")),
                searchParams.get("redirect_uri")
            );
            onSignUpSuccess({ email: values.email, token: res.data.token });
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                if (e.response && e.response.status === 400) {
                    setErrorMessage(e.response.data.message);
                }
            }
        }
        setIsLoading(false);
    };

    const renderHeader = () => {
        if (fromInvite && inviteInfo) {
            return (
                <React.Fragment>
                    <div className={"flex justify-center"}>
                        <div
                            className={
                                "bg-white rounded-8 w-80 h-80 flex items-center justify-center logo-shadow"
                            }
                        >
                            {inviteInfo.accountLogo ? (
                                <ZenImage
                                    src={inviteInfo.accountLogo}
                                    className={"object-contain h-full"}
                                />
                            ) : (
                                <span
                                    className={
                                        "text-42 font-bold uppercase text-info"
                                    }
                                >
                                    {getFirstWord(inviteInfo.accountName)}
                                </span>
                            )}
                        </div>
                    </div>
                    <p className={"text-24 font-bold text-center"}>
                        You&lsquo;ve been invited to join{" "}
                        {inviteInfo.accountName} on Menuzen.
                    </p>
                    <p className={"text-primary-80 text-center"}>
                        New to Menuzen? Create an account by entering your
                        details below.
                        <br /> If you already have an account you can{" "}
                        <Link
                            to={{
                                pathname: "/login",
                                search: searchParams.toString(),
                            }}
                            className={"font-bold underline"}
                        >
                            log in
                        </Link>
                        .
                    </p>
                </React.Fragment>
            );
        } else {
            return (
                <div className="text-center md:text-left">
                    <p className="text-28 font-bold">Get Started</p>

                    <div className={"text-primary-80"}>
                        Create a free account to get started.
                        <br />
                        Already have an account?{" "}
                        <Link
                            to={`/login?redirect_uri=${getRedirectUri(
                                redirectUri
                            )}`}
                            className={"font-bold underline"}
                        >
                            Log In
                        </Link>
                    </div>
                </div>
            );
        }
    };

    return (
        <React.Fragment>
            {isGettingInviteData ? (
                <Fragment>
                    <div className={"h-full flex items-center justify-center"}>
                        <Loading />
                    </div>
                    <div />
                </Fragment>
            ) : (
                <React.Fragment>
                    <div className="pt-28 md:pt-0">
                        {renderHeader()}
                        {searchParams.get("use-email") && (
                            <FormContainer
                                schema={authSchema()}
                                onSubmit={(values) => {
                                    onSignUp(values as SignUpFormValues);
                                }}
                            >
                                <p className="signup-error text-center min-h-20 text-error">
                                    {errorMessage}
                                </p>
                                <div className={"mb-16"}>
                                    <TextFormField
                                        name="firstName"
                                        type="text"
                                        placeholder={"First Name"}
                                        fullWidth
                                    />
                                </div>
                                <div className={"mb-16"}>
                                    <TextFormField
                                        name="lastName"
                                        type="text"
                                        placeholder={"Last Name"}
                                        fullWidth
                                    />
                                </div>
                                <div className={"mb-16"}>
                                    <TextFormField
                                        name="email"
                                        type="email"
                                        placeholder={"Email"}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className={"mb-16"}>
                                    <TextFormField
                                        name="password"
                                        type="password"
                                        placeholder={"Password"}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PasswordIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className="mt-16 md:mt-24">
                                    <LoadingButton
                                        variant="contained"
                                        loading={isLoading}
                                        type="submit"
                                        fullWidth={true}
                                    >
                                        Sign Up
                                        <span
                                            className={
                                                "text-8 ml-8 hidden md:inline"
                                            }
                                        >
                                            <ArrowRightIcon />
                                        </span>
                                    </LoadingButton>
                                </div>
                            </FormContainer>
                        )}
                        {!searchParams.get("use-email") && (
                            <div>
                                <div className={"mt-16"}>
                                    <Button
                                        variant="contained"
                                        fullWidth={true}
                                        size="large"
                                        onClick={() => {
                                            searchParams.set(
                                                "use-email",
                                                "true"
                                            );
                                            navigate({
                                                pathname: location.pathname,
                                                search:
                                                    "?" +
                                                    searchParams.toString(),
                                            });
                                        }}
                                    >
                                        Continue with Email
                                    </Button>
                                </div>
                                {!isSupportSNS() && (
                                    <div
                                        className={
                                            "border-b border-primary-40 mt-16"
                                        }
                                    >
                                        <p
                                            className={
                                                "text-12 text-primary-70 pb-16 text-center px-8"
                                            }
                                        >
                                            Please open{" "}
                                            {getGetStartedUrl(
                                                searchParams.get("redirect_uri")
                                            )}{" "}
                                            on a web browser to sign up with
                                            Google or Facebook
                                        </p>
                                    </div>
                                )}
                                {isSupportSNS() && (
                                    <Fragment>
                                        <div className={"mt-16"}>
                                            <Button
                                                variant="outlined"
                                                fullWidth={true}
                                                size="large"
                                                sx={{
                                                    fontSize: {
                                                        md: px2rem(14),
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    },
                                                }}
                                                onClick={loginGoogle}
                                            >
                                                <GoogleIcon />
                                                <span className="ml-8 md:ml-4">
                                                    Continue with Google
                                                </span>
                                            </Button>
                                        </div>
                                        <div className={"mt-16"}>
                                            <Button
                                                variant="outlined"
                                                fullWidth={true}
                                                size="large"
                                                sx={{
                                                    fontSize: {
                                                        md: px2rem(14),
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    },
                                                }}
                                                onClick={loginFacebook}
                                            >
                                                <FacebookIcon />
                                                <span className="ml-8 md:ml-4">
                                                    Continue with Facebook
                                                </span>
                                            </Button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        )}
                        {!fromInvite ? (
                            <div />
                        ) : (
                            <AgreeTerm
                                className={"flex flex-col items-center mt-16"}
                            />
                        )}
                    </div>

                    {fromInvite ? <div /> : <AgreeTerm />}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default SignUpForm;
