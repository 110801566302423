import { PaletteOptions } from "@mui/material/styles/createPalette";
import { alpha, createTheme } from "@mui/material";

const { palette: paletteDefault } = createTheme();
const createColor = (mainColor: string) =>
    paletteDefault.augmentColor({ color: { main: mainColor } });
export const primaryColor = {
    main: "#242A36",
    80: "#494E58",
    70: "#6D7179",
    50: "#ABAEB2",
    40: "#E0E1E3",
    20: "#E8E8E9",
    15: "#f4f4f5",
    10: "#F8F8F9",
    5: "#EFEFF0",
};
export const whiteColor = {
    main: "#ffffff",
    90: alpha("#ffffff", 0.9),
};

export const warningColor = {
    main: "#FFD05A",
    light: "#FFF1CE",
};

export const secondaryColor = {
    main: "#55E5B8",
    light: "#F4FCFC",
};
export const successColor = {
    main: "#30B9BC",
    light: "#DDFAF1",
    50: "#00CACE",
};
export const errorColor = {
    main: "#FF8989",
    light: "#FFDEDE",
};

export const grayColor = {
    main: "#E8E8E9",
};

export const gradientColor = {
    main: "linear-gradient(159.94deg, #FDF2ED -4.37%, #D4F3F4 104.34%)",
    light: "linear-gradient(85.83deg, #FBF7F6 1.91%, #F4FCFC 99.17%)",
};

const palette: PaletteOptions = {
    primary: {
        main: primaryColor.main,
    },
    secondary: {
        main: secondaryColor.main,
    },
    error: {
        main: errorColor.main,
    },
    warning: {
        main: warningColor.main,
    },
    success: {
        main: successColor.main,
    },
    divider: grayColor.main,
    text: {
        primary: "#242A36",
        secondary: "#494E58",
        disabled: "#ABAEB2",
    },
    success50: createColor(successColor["50"]),
    primary80: createColor(primaryColor["80"]),
    primary70: createColor(primaryColor["70"]),
    primary50: createColor(primaryColor["50"]),
    primary40: createColor(primaryColor["40"]),
    primary20: createColor(primaryColor["20"]),
    primary15: createColor(primaryColor["15"]),
    primary10: createColor(primaryColor["10"]),
    primary5: createColor(primaryColor["5"]),
    white: whiteColor,
};

export const paletteConfig = createTheme({
    palette,
});
