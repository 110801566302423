import React from "react";
function GoogleIcon() {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.74 6.64693C12.74 6.18602 12.6986 5.74284 12.6218 5.31738H6.5V7.8317H9.99818C9.8475 8.6442 9.38955 9.33261 8.70114 9.79352V11.4244H10.8018C12.0309 10.2928 12.74 8.62647 12.74 6.64693Z"
                fill="#4285F4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50004 12.9994C8.25504 12.9994 9.72641 12.4173 10.8019 11.4246L8.70118 9.79369C8.11913 10.1837 7.37459 10.4141 6.50004 10.4141C4.80709 10.4141 3.37413 9.27074 2.863 7.73438H0.691406V9.41847C1.76095 11.5428 3.95913 12.9994 6.50004 12.9994Z"
                fill="#34A853"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.86295 7.73515C2.73295 7.34515 2.65909 6.92855 2.65909 6.50015C2.65909 6.07174 2.73295 5.65515 2.86295 5.26515V3.58105H0.691364C0.251136 4.45855 0 5.45128 0 6.50015C0 7.54901 0.251136 8.54174 0.691364 9.41924L2.86295 7.73515Z"
                fill="#FBBC05"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50004 2.58523C7.45436 2.58523 8.31118 2.91318 8.98482 3.55727L10.8491 1.69295C9.72345 0.644091 8.25209 0 6.50004 0C3.95913 0 1.76095 1.45659 0.691406 3.58091L2.863 5.265C3.37413 3.72864 4.80709 2.58523 6.50004 2.58523Z"
                fill="#EA4335"
            />
        </svg>
    );
}

export default GoogleIcon;
