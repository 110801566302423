import React, { FC, Fragment } from "react";
import { Button } from "@mui/material";

interface AcceptInviteInvalidProps {
    email: string;
}
const AcceptInviteInvalid: FC<AcceptInviteInvalidProps> = ({ email }) => {
    const handleChooseAnother = () => {
        window.location.href = `/logout?redirect_uri=${window.encodeURIComponent(
            window.location.href
        )}`;
    };
    return (
        <Fragment>
            <div className={"flex-auto flex flex-col md:flex-none"}>
                <div className="text-center flex-auto">
                    <p className="text-24 font-bold mt-40 leading-6 md:mt-12 md:text-20">
                        Oops.
                    </p>
                    <p className="text-20 font-bold">
                        The invite link you clicked on was sent to an email
                        that’s not associated with your current profile.
                    </p>
                    <p className="text-primary-80 mt-8">
                        If you want to use this profile you can ask the account
                        owner to send a new invite to <span>{email}</span>.
                    </p>
                    <p className="text-primary-80 mt-20">
                        To accept the invite sign up or log in with the correct
                        email by clicking the button below.
                    </p>
                </div>
                <div className="mt-20">
                    <Button
                        variant="contained"
                        fullWidth={true}
                        onClick={handleChooseAnother}
                    >
                        Use Other Account
                    </Button>
                </div>
            </div>
            <div />
        </Fragment>
    );
};

export default AcceptInviteInvalid;
