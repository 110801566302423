import React, { useEffect } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LoginCallbackPage from "./pages/LoginCallbackPage";
import SignUpPage from "./pages/SignUpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AcceptInvitePage from "./pages/AcceptInvitePage";
import ProfilePage from "./pages/ProfilePage";
import LinkCallbackPage from "./pages/LinkCallbackPage";
import ProtectedRoute from "./common/ProtectedRoute";
import PublicRoute from "./common/PublicRoute";
import VerifiedSuccessPage from "./pages/VerifiedSuccessPage";
import { getRedirectUri } from "./shared/utils/url/get-redirect-uri";
import PageLayout from "./common/layout/PageLayout";

function App() {
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");

    useEffect(() => {
        const disableGestureStart = (e: Event) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", disableGestureStart);
        return () => {
            document.removeEventListener("gesturestart", disableGestureStart);
        };
    }, []);
    return (
        <Routes>
            <Route path="" element={<PageLayout />}>
                <Route
                    path="/"
                    element={
                        <Navigate
                            to={`/login?redirect_uri=${getRedirectUri(
                                redirectUri
                            )}`}
                            replace
                        />
                    }
                />
                <Route path="" element={<PublicRoute />}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/login/callback"
                        element={<LoginCallbackPage />}
                    />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/verified" element={<VerifiedSuccessPage />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPasswordPage />}
                    />
                    <Route
                        path="/reset-password/:token"
                        element={<ResetPasswordPage />}
                    />
                </Route>

                <Route path="/invite/:token" element={<AcceptInvitePage />} />
                <Route path="/link/callback" element={<LinkCallbackPage />} />
            </Route>
            <Route
                path="/profile"
                element={
                    <ProtectedRoute>
                        <ProfilePage />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
}

export default App;
