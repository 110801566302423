import React, { FC } from "react";

const BackIcon: FC = () => {
    return (
        <svg
            className="svg-inline"
            viewBox="0 0 25 26"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.20898 13H19.7923"
                strokeWidth="1.9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.20898 13L11.459 19.25"
                strokeWidth="1.9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.20898 13L11.459 6.75"
                strokeWidth="1.9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default BackIcon;
