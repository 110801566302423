import React from "react";
function FacebookIcon() {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 6.5C13 2.90977 10.0902 0 6.5 0C2.90977 0 0 2.90977 0 6.5C0 9.74492 2.37656 12.4338 5.48438 12.9213V8.37891H3.83398V6.5H5.48438V5.06797C5.48438 3.43916 6.4543 2.53906 7.93965 2.53906C8.65059 2.53906 9.39453 2.66602 9.39453 2.66602V4.26563H8.57441C7.76699 4.26563 7.51562 4.76709 7.51562 5.28125V6.5H9.31836L9.03018 8.37891H7.51562V12.9213C10.6234 12.4338 13 9.74492 13 6.5Z"
                fill="#1877F2"
            />
            <path
                d="M9.03018 8.37891L9.31836 6.5H7.51562V5.28125C7.51562 4.76709 7.76699 4.26562 8.57441 4.26562H9.39453V2.66602C9.39453 2.66602 8.65059 2.53906 7.93965 2.53906C6.4543 2.53906 5.48438 3.43916 5.48438 5.06797V6.5H3.83398V8.37891H5.48438V12.9213C5.81572 12.9733 6.15469 13 6.5 13C6.84531 13 7.18428 12.9733 7.51562 12.9213V8.37891H9.03018Z"
                fill="white"
            />
        </svg>
    );
}

export default FacebookIcon;
