import {
    SwipeableDrawer,
    SwipeableDrawerProps,
    Typography,
} from "@mui/material";
import React, { FC } from "react";
import BarIcon from "../icon/BarIcon";
import BackIcon from "../icon/BackIcon";
import CircleCloseIcon from "../icon/CircleCloseIcon";

export interface DialogProps extends SwipeableDrawerProps {
    title?: string;
    subtitle?: string;
    onBack?: () => void;
    showClose?: boolean;
    childClassName?: string;
}

const Dialog: FC<DialogProps> = ({
    title,
    subtitle,
    showClose,
    onBack,
    onClose,
    children,
    childClassName,
    ...props
}) => {
    return (
        <SwipeableDrawer onClose={onClose} {...props}>
            <div className={"text-center text-primary-40 text-6"}>
                <BarIcon />
            </div>
            {title && (
                <div className={"flex w-full px-28 mt-12"}>
                    {onBack && (
                        <div
                            className="flex-shrink-0 text-24 text-primary-70"
                            onClick={onBack}
                        >
                            <BackIcon />
                        </div>
                    )}
                    <Typography
                        variant="h6"
                        component="div"
                        className={"text-center flex-auto px-20"}
                    >
                        {title}
                    </Typography>
                    {showClose && (
                        <div
                            className="flex-shrink-0 text-24 text-primary-70"
                            onClick={onClose}
                        >
                            <CircleCloseIcon />
                        </div>
                    )}
                </div>
            )}
            {subtitle && (
                <Typography
                    variant="inherit"
                    component="p"
                    className={"text-center flex-auto pb-20 text-primary-70"}
                >
                    {subtitle}
                </Typography>
            )}
            <div className={childClassName || "overflow-y-auto"}>
                {children}
            </div>
        </SwipeableDrawer>
    );
};

export default Dialog;
