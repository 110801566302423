import React, { FC, Fragment, useState } from "react";
import * as Yup from "yup";
import { useParams } from "react-router";
import PasswordIcon from "../../common/icon/PasswordIcon";
import { resetPasswordApi } from "../../shared/services/reset-password/reset-password-api";
import FormContainer from "../../common/form/FormContainer";
import TextFormField from "../../common/form/TextFormField";
import { InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const resetPasswordSchema = () =>
    Yup.object().shape({
        password: Yup.string()
            .required("Password is required.")
            .min(8, "Password must be at least 8 characters.")
            .max(20, "Password may not be greater than 20 characters."),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Password confirmation does not match."
        ),
    });

interface ResetPasswordFormValues {
    password: string;
}

interface ResetPasswordFormProps {
    onSuccess: () => void;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSuccess }) => {
    const { token = "" } = useParams();
    const [isResetting, setIsResetting] = useState(false);

    const handleResetPassword = async (values: ResetPasswordFormValues) => {
        setIsResetting(true);
        await resetPasswordApi.setNewPassword(token, values.password);
        onSuccess();
    };
    return (
        <Fragment>
            <div className="flex flex-col flex-auto justify-between mt-12 mb-12 md:justify-start md:flex-none">
                <FormContainer
                    className="flex flex-col flex-auto justify-between mt-12 mb-12 md:justify-start md:flex-none"
                    schema={resetPasswordSchema()}
                    onSubmit={handleResetPassword}
                >
                    <div>
                        <p className="text-24 font-bold mt-40 leading-6 md:mt-12 md:text-20">
                            Change Password
                        </p>
                        <p className="text-primary-80 mt-12">
                            Thanks for verifying your email address. Enter your
                            new password below.
                        </p>
                        <div className={"mt-20"}>
                            <TextFormField
                                name="password"
                                type="password"
                                placeholder={"New Password"}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className={"mt-16"}>
                            <TextFormField
                                name="confirmPassword"
                                type="password"
                                placeholder={"Confirm New Password"}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className="mt-16 md:mt-12">
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={isResetting}
                            fullWidth={true}
                        >
                            Confirm
                        </LoadingButton>
                    </div>
                </FormContainer>
            </div>
            <div />
        </Fragment>
    );
};

export default ResetPasswordForm;
