import React from "react";
const EditIcon = () => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 21 21"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.00065 6.33203H5.50065C5.05862 6.33203 4.6347 6.50763 4.32214 6.82019C4.00958 7.13275 3.83398 7.55667 3.83398 7.9987V15.4987C3.83398 15.9407 4.00958 16.3646 4.32214 16.6772C4.6347 16.9898 5.05862 17.1654 5.50065 17.1654H13.0007C13.4427 17.1654 13.8666 16.9898 14.1792 16.6772C14.4917 16.3646 14.6673 15.9407 14.6673 15.4987V12.9987"
                stroke="#242A36"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 12.9995H10.5L17.5833 5.9162C17.9149 5.58468 18.1011 5.13505 18.1011 4.6662C18.1011 4.19736 17.9149 3.74772 17.5833 3.4162C17.2518 3.08468 16.8022 2.89844 16.3333 2.89844C15.8645 2.89844 15.4149 3.08468 15.0833 3.4162L8 10.4995V12.9995Z"
                stroke="#242A36"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.834 4.66602L16.334 7.16602"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default EditIcon;
