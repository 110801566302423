import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./shared/services/middlewares/axios";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { themeConfig } from "./common/theme/theme-config";
import { SnackbarProvider } from "notistack";
import Snackbar from "./common/Snackbar";
import Zoom from "./common/Zoom";
import { ApolloProvider } from "@apollo/client";
import client from "./common/apollo/client";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <ThemeProvider theme={themeConfig}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    Components={{
                        default: Snackbar,
                        success: Snackbar,
                        info: Snackbar,
                        error: Snackbar,
                        warning: Snackbar,
                    }}
                    preventDuplicate={true}
                    autoHideDuration={2000}
                    TransitionComponent={Zoom}
                    maxSnack={2}
                >
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SnackbarProvider>
            </ThemeProvider>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
