import React, { forwardRef } from "react";
import { CustomContentProps, SnackbarKey } from "notistack";
import { Alert, AlertColor } from "@mui/material";
import { AlertProps } from "@mui/material/Alert/Alert";

type CloseSnackbarAction = (key?: SnackbarKey) => void;

// eslint-disable-next-line react/display-name
const Snackbar = forwardRef<
    HTMLDivElement,
    CustomContentProps & { component: React.ReactNode }
>((props, forwardedRef) => {
    const { style, message, variant, component, persist, action } = props;
    const closeAction = action as CloseSnackbarAction;
    return (
        <Alert
            ref={forwardedRef}
            color={
                (variant !== "default"
                    ? variant
                    : "primary") as AlertProps["color"]
            }
            severity={(variant !== "default" ? variant : "info") as AlertColor}
            style={style}
            onClose={persist ? () => closeAction() : undefined}
        >
            {component ? component : message}
        </Alert>
    );
});

export default Snackbar;
