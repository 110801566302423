import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { px2rem } from "./px2rem";
import {
    errorColor,
    primaryColor,
    secondaryColor,
    successColor,
    warningColor,
} from "./palette";

export const muiChip: {
    defaultProps?: ComponentsProps["MuiChip"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiChip"];
    variants?: ComponentsVariants["MuiChip"];
} = {
    styleOverrides: {
        root: {
            height: px2rem(20),
            fontSize: px2rem(11),
            fontWeight: 600,
            borderRadius: 4,
        },
        label: {
            paddingLeft: px2rem(6),
            paddingRight: px2rem(6),
        },
    },

    variants: [
        {
            props: { color: "warning" },
            style: { color: primaryColor.main, background: warningColor.light },
        },
        {
            props: { color: "secondary" },
            style: {
                color: primaryColor.main,
                background: secondaryColor.light,
            },
        },
        {
            props: { color: "success" },
            style: { color: primaryColor.main, background: successColor.light },
        },
        {
            props: { color: "error" },
            style: { color: primaryColor.main, background: errorColor.light },
        },
        {
            props: { color: "primary" },
            style: { background: primaryColor["70"] },
        },
    ],
};
