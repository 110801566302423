import React from "react";

const ArrowLeftIcon = () => {
    return (
        <svg
            className={"svg-inline"}
            viewBox="0 0 18 16"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.62109 7.94067H16.2044"
                strokeWidth="1.9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.62109 7.94067L7.87109 14.1907"
                strokeWidth="1.9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.62109 7.94067L7.87109 1.69067"
                strokeWidth="1.9"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowLeftIcon;
