import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { primaryColor } from "./palette";

export const muiSwitch: {
    defaultProps?: ComponentsProps["MuiSwitch"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiSwitch"];
    variants?: ComponentsVariants["MuiSwitch"];
} = {
    defaultProps: {
        color: "success",
    },
    styleOverrides: {
        root: {
            height: 24,
            width: 44,
            padding: 0,
        },
        switchBase: {
            padding: 4,
        },
        thumb: {
            background: "white",
            width: 16,
            height: 16,
            boxShadow: "0.720929px 0.720929px 5.76744px rgba(0, 0, 0, 0.12);",
        },
        track: {
            borderRadius: 9999,
            background: primaryColor["50"],
            boxShadow: "inset 0px 0.720929px 1.44186px rgba(0, 0, 0, 0.1);",
        },
    },
};
