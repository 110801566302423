import { AxiosError } from "axios";
import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { ApiResponse } from "../shared/types/network/api-response";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { webAuth } from "../lib/auth";
import EmailIcon from "../common/icon/EmailIcon";
import PasswordIcon from "../common/icon/PasswordIcon";
import GoogleIcon from "../common/icon/GoogleIcon";
import FacebookIcon from "../common/icon/FacebookIcon";
import CheckColorIcon from "../common/icon/color/CheckColorIcon";
import AppLogo from "../layout/AppLogo";
import { authApi } from "../shared/services/auth/auth-api";
import { getRedirectUri } from "../shared/utils/url/get-redirect-uri";
import {
    getAppConfigs,
    getAppSource,
    getGetStartedUrl,
} from "../shared/utils/string/app-source";
import FormContainer from "../common/form/FormContainer";
import TextFormField from "../common/form/TextFormField";
import { Button, Grid, InputAdornment } from "@mui/material";
import { px2rem } from "../common/theme/px2rem";
import { LoadingButton } from "@mui/lab";
import ArrowRightIcon from "../common/icon/ArrowRightIcon";
import ArrowLeftIcon from "../common/icon/ArrowLeftIcon";
import { isSupportSNS } from "../lib/sns";

interface LoginFormValues {
    email: string;
    password: string;
}
export const authSchema = () =>
    Yup.object().shape({
        email: Yup.string()
            .required("Email is required.")
            .email("Email field must be a valid email."),
        password: Yup.string()
            .required("Password is required.")
            .min(8, "Password must be at least 8 characters.")
            .max(20, "Password may not be greater than 20 characters."),
    });

const LoginPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");
    const isFromVerifyLink = !!searchParams.get("fromVerified");

    const configs = getAppConfigs(redirectUri);

    const callbackUrl = () => {
        let url =
            window.location.protocol +
            "//" +
            window.location.host +
            process.env.REACT_APP_AUTH_CALLBACK;
        if (redirectUri) {
            url += `?redirect_uri=${getRedirectUri(redirectUri)}`;
        }
        return url;
    };

    const loginGoogle = () => {
        webAuth.authorize({
            redirectUri: callbackUrl(),
            connection: "google-oauth2",
        });
    };

    const loginFacebook = () => {
        webAuth.authorize({
            redirectUri: callbackUrl(),
            connection: "facebook",
        });
    };

    const loginEmail = async ({ email, password }: LoginFormValues) => {
        setIsLoading(true);
        setErrorMessage("");
        try {
            await authApi.login(
                email,
                password,
                getAppSource(searchParams.get("redirect_uri"))
            );
            window.location.href = redirectUri || "https://menuzen.com";
        } catch (e: unknown) {
            const { response } = e as AxiosError<ApiResponse<string>>;
            if (response?.data?.message) {
                setErrorMessage(response?.data?.message);
            }
            setIsLoading(false);
        }
    };
    const renderHeader = () => {
        if (isFromVerifyLink) {
            return (
                <Fragment>
                    <div className={"text-80 flex items-center justify-center"}>
                        <CheckColorIcon />
                    </div>
                    <p
                        className={
                            "text-18 md:text-34 font-semibold md:font-bold text-center md:mt-12 tracking-tighter"
                        }
                    >
                        Success! Your email has been verified.
                    </p>
                    <p
                        className={
                            "text-14 text-center text-grey-dark mt-12 whitespace-pre-line"
                        }
                    >
                        Please enter your details below to login into your
                        account.
                    </p>
                </Fragment>
            );
        } else {
            return (
                <div className="text-center md:text-left">
                    <p className="text-28 font-bold">Welcome Back</p>
                    {configs.ALLOW_REGISTER && (
                        <>
                            <p className={"text-primary-80"}>
                                Log in to get started.
                            </p>

                            <div className={"text-primary-80"}>
                                Don&lsquo;t have an account?{" "}
                                <a
                                    href={`https://${getGetStartedUrl(
                                        searchParams.get("redirect_uri")
                                    )}`}
                                    className={"font-bold underline"}
                                >
                                    Get Started
                                </a>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    };
    return (
        <div
            className={
                "p-40 md:py-60 md:px-70 flex flex-auto flex-col md:justify-between"
            }
        >
            <div>
                <div className={"text-16 md:hidden"}>
                    <span
                        className={"cursor-pointer"}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeftIcon />
                    </span>
                </div>
                <div className="flex items-center justify-center flex-shrink-0 md:justify-start">
                    <AppLogo />
                </div>
            </div>
            <Fragment>
                <div className="pt-28 md:pt-0">
                    {renderHeader()}
                    <div className="text-center min-h-20 text-error md:text-left md:mt-8">
                        {errorMessage}
                    </div>
                    <FormContainer
                        schema={authSchema()}
                        onSubmit={(v) => loginEmail(v as LoginFormValues)}
                    >
                        <div className="mt-4">
                            <TextFormField
                                name="email"
                                type="email"
                                placeholder={"Email"}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="mt-16">
                            <TextFormField
                                name="password"
                                type="password"
                                placeholder={"Password"}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="mt-16 md:mt-24">
                            <LoadingButton
                                variant="contained"
                                loading={isLoading}
                                type="submit"
                                fullWidth={true}
                            >
                                <p className={"flex items-center"}>
                                    Log In
                                    <span
                                        className={
                                            "text-8 ml-8 hidden md:inline"
                                        }
                                    >
                                        <ArrowRightIcon />
                                    </span>
                                </p>
                            </LoadingButton>
                        </div>
                    </FormContainer>

                    {isSupportSNS() && (
                        <Fragment>
                            <div
                                className={"relative h-12 mt-16 mb-16 md:mt-24"}
                            >
                                <div
                                    className={
                                        "h-1 bg-primary-50 top-1/2 absolute left-0 right-0"
                                    }
                                />
                                <div
                                    className={
                                        "bg-white absolute z-10 left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 text-12 leading-none px-10"
                                    }
                                >
                                    OR
                                </div>
                            </div>
                            <Grid
                                container
                                columnSpacing={1.5}
                                rowSpacing={{ xs: 2 }}
                                justifyContent="center"
                            >
                                {configs.GOOGLE_LOGIN && (
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            variant="outlined"
                                            fullWidth={true}
                                            sx={{
                                                fontSize: {
                                                    md: px2rem(14),
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                },
                                            }}
                                            onClick={loginGoogle}
                                        >
                                            <GoogleIcon />
                                            <span className="ml-8 md:ml-4">
                                                Continue with Google
                                            </span>
                                        </Button>
                                    </Grid>
                                )}
                                {configs.FACEBOOK_LOGIN && (
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            variant="outlined"
                                            fullWidth={true}
                                            sx={{
                                                fontSize: {
                                                    md: px2rem(14),
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                },
                                            }}
                                            onClick={loginFacebook}
                                        >
                                            <FacebookIcon />
                                            <span className="ml-8 md:ml-4">
                                                Continue with Facebook
                                            </span>
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Fragment>
                    )}
                </div>
                <div className="text-center mt-16 md:text-left">
                    <Link
                        to={`/forgot-password?redirect_uri=${getRedirectUri(
                            redirectUri
                        )}`}
                        className="underline text-primary-70 font-bold"
                    >
                        Forgot Your Password?
                    </Link>
                </div>
                {!isSupportSNS() && (
                    <div className={"border-t border-primary-40 mt-16"}>
                        <p
                            className={
                                "text-12 text-primary-70 pt-16 text-center px-16"
                            }
                        >
                            Please open{" "}
                            {getGetStartedUrl(searchParams.get("redirect_uri"))}{" "}
                            on a web browser to sign up with Google or Facebook
                        </p>
                    </div>
                )}
            </Fragment>
        </div>
    );
};

export default LoginPage;
