/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Datetime: any;
  JSONObject: any;
  about_String_maxLength_1500: any;
  address_String_maxLength_255: any;
  bookingUrl_String_maxLength_1024: any;
  city_String_maxLength_255: any;
  countryCode_String_maxLength_255: any;
  country_String_maxLength_50: any;
  country_String_maxLength_255: any;
  currency_String_maxLength_20: any;
  customAddress_String_maxLength_255: any;
  description_String_maxLength_1500: any;
  email_String_NotNull_maxLength_320_format_email: any;
  email_String_maxLength_320: any;
  email_String_maxLength_320_format_email: any;
  image_String_maxLength_1024: any;
  kilojoules_Int_min_0_max_10000: any;
  label_String_maxLength_255: any;
  logo_String_maxLength_1024: any;
  mainImage_String_maxLength_1024: any;
  name_String_NotNull_maxLength_50: any;
  name_String_NotNull_maxLength_255: any;
  name_String_NotNull_minLength_1_maxLength_50: any;
  name_String_NotNull_minLength_1_maxLength_255: any;
  name_String_maxLength_255: any;
  name_String_minLength_1_maxLength_50: any;
  note_String_maxLength_255: any;
  path_String_NotNull_maxLength_50_pattern_09az_: any;
  path_String_maxLength_50_pattern_09az_: any;
  phone_String_maxLength_20: any;
  postcode_String_maxLength_255: any;
  price_Float_min_0_max_100000000: any;
  state_String_maxLength_255: any;
  subtitle_String_maxLength_255: any;
  timezone_String_maxLength_255: any;
  username_String_NotNull_minLength_3_maxLength_30_pattern_09az_: any;
  username_String_maxLength_30_pattern_09az_: any;
  website_String_maxLength_1024: any;
};

export type Account = {
  __typename?: 'Account';
  about: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  bookingUrl: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  claimToken: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  cuisineType: Array<CuisineType>;
  currency: Maybe<Scalars['String']>;
  currentPlan: CurrentPlan;
  customAddress: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  facebookSyncPage: Maybe<AccountFacebookPage>;
  id: Scalars['Int'];
  invitation: Array<UserAccountInvitation>;
  isCleared: Maybe<Scalars['Boolean']>;
  latitude: Maybe<Scalars['Float']>;
  logo: Maybe<Scalars['String']>;
  logoList: Maybe<Array<Scalars['String']>>;
  longitude: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  note: Maybe<Scalars['String']>;
  openingHours: Array<OpeningHours>;
  orderLinks: Array<AccountLink>;
  owner: Maybe<User>;
  ownerId: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  planCanceledAt: Maybe<Scalars['DateTime']>;
  planCode: Scalars['String'];
  planExpiredAt: Maybe<Scalars['DateTime']>;
  planTrialEndAt: Maybe<Scalars['DateTime']>;
  postcode: Maybe<Scalars['String']>;
  requireDowngrade: Maybe<Scalars['Boolean']>;
  restaurantType: Maybe<RestaurantType>;
  siteDesign: Maybe<SiteDesign>;
  socialLinks: Array<AccountLink>;
  state: Maybe<Scalars['String']>;
  stripeCustomerId: Maybe<Scalars['String']>;
  stripeSubId: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
  totalExtraImages: Maybe<Scalars['Int']>;
  totalInvite: Maybe<Scalars['Int']>;
  totalItems: Scalars['Int'];
  totalLiveMenu: Maybe<Scalars['Int']>;
  totalUsers: Scalars['Int'];
  username: Scalars['String'];
  users: Array<UserAccount>;
  webSetting: Maybe<AccountWebSetting>;
  website: Maybe<Scalars['String']>;
};

export type AccountConnection = {
  __typename?: 'AccountConnection';
  edges: Array<AccountEdge>;
  pageInfo: Maybe<PageInfo>;
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  node: Account;
};

export type AccountFacebookPage = {
  __typename?: 'AccountFacebookPage';
  accountId: Scalars['Int'];
  errorData: Maybe<Scalars['String']>;
  invalidFields: Maybe<Scalars['String']>;
  lastError: Maybe<Scalars['String']>;
  lastSync: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  pageId: Maybe<Scalars['String']>;
  syncFields: Maybe<Scalars['String']>;
};

export type AccountLink = {
  __typename?: 'AccountLink';
  id: Scalars['Int'];
  label: Maybe<Scalars['String']>;
  link: Scalars['String'];
  type: Scalars['String'];
};

export enum AccountLinkGroup {
  Order = 'ORDER',
  Social = 'SOCIAL'
}

export type AccountLinkInput = {
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  link: Scalars['String'];
  type: Scalars['String'];
};

export enum AccountUserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER'
}

export enum AccountUserRoleInput {
  Admin = 'ADMIN'
}

export type AccountWebSetting = {
  __typename?: 'AccountWebSetting';
  customFaviconUrl: Maybe<Scalars['String']>;
  enableCustomFavicon: Maybe<Scalars['Boolean']>;
  showDefaultBranding: Maybe<Scalars['Boolean']>;
};

export type AccountWebSettingInput = {
  customFaviconUrl?: InputMaybe<Scalars['String']>;
  enableCustomFavicon?: InputMaybe<Scalars['Boolean']>;
  showDefaultBranding?: InputMaybe<Scalars['Boolean']>;
};

export type AccountWebSettingResult = {
  __typename?: 'AccountWebSettingResult';
  customFaviconUrl: Maybe<Scalars['String']>;
  enableCustomFavicon: Maybe<Scalars['Boolean']>;
  showDefaultBranding: Maybe<Scalars['Boolean']>;
};

export type AddAccountInput = {
  about?: InputMaybe<Scalars['about_String_maxLength_1500']>;
  address?: InputMaybe<Scalars['address_String_maxLength_255']>;
  bookingUrl?: InputMaybe<Scalars['bookingUrl_String_maxLength_1024']>;
  city?: InputMaybe<Scalars['city_String_maxLength_255']>;
  country?: InputMaybe<Scalars['country_String_maxLength_255']>;
  countryCode?: InputMaybe<Scalars['countryCode_String_maxLength_255']>;
  cuisineTypeIds?: InputMaybe<Array<Scalars['Int']>>;
  currency?: InputMaybe<Scalars['currency_String_maxLength_20']>;
  customAddress?: InputMaybe<Scalars['customAddress_String_maxLength_255']>;
  email?: InputMaybe<Scalars['email_String_maxLength_320_format_email']>;
  latitude?: InputMaybe<Scalars['Float']>;
  logo?: InputMaybe<Scalars['logo_String_maxLength_1024']>;
  logoList?: InputMaybe<Array<Scalars['String']>>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['name_String_NotNull_minLength_1_maxLength_50'];
  note?: InputMaybe<Scalars['note_String_maxLength_255']>;
  openingHours?: InputMaybe<Array<OpeningHoursInput>>;
  orderLinks?: InputMaybe<Array<AccountLinkInput>>;
  phone?: InputMaybe<Scalars['phone_String_maxLength_20']>;
  postcode?: InputMaybe<Scalars['postcode_String_maxLength_255']>;
  restaurantTypeId?: InputMaybe<Scalars['Int']>;
  socialLinks?: InputMaybe<Array<AccountLinkInput>>;
  state?: InputMaybe<Scalars['state_String_maxLength_255']>;
  timezone?: InputMaybe<Scalars['timezone_String_maxLength_255']>;
  username: Scalars['username_String_NotNull_minLength_3_maxLength_30_pattern_09az_'];
  website?: InputMaybe<Scalars['website_String_maxLength_1024']>;
};

export type AddAccountResult = {
  __typename?: 'AddAccountResult';
  account: Maybe<Account>;
};

export type AddDesignInput = {
  description?: InputMaybe<Scalars['description_String_maxLength_1500']>;
  designSettings?: InputMaybe<Scalars['JSONObject']>;
  elements?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['name_String_NotNull_maxLength_255'];
  path?: InputMaybe<Scalars['path_String_maxLength_50_pattern_09az_']>;
  themeId?: InputMaybe<Scalars['Int']>;
};

export type AddDesignResult = {
  __typename?: 'AddDesignResult';
  design: Design;
};

export type AddFontsInput = {
  name: Array<Scalars['String']>;
  url: Array<Scalars['String']>;
};

export type AddFontsResult = {
  __typename?: 'AddFontsResult';
  fonts: Array<Font>;
};

export type AddItemInput = {
  category?: InputMaybe<CategoryInput>;
  description?: InputMaybe<Scalars['description_String_maxLength_1500']>;
  labelIds?: InputMaybe<Array<Scalars['Int']>>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  mainImage?: InputMaybe<Scalars['String']>;
  modifierIds?: InputMaybe<Array<Scalars['Int']>>;
  name: Scalars['name_String_NotNull_minLength_1_maxLength_255'];
  outOfStock?: InputMaybe<Scalars['Boolean']>;
  subImages?: InputMaybe<Array<Scalars['String']>>;
  subtitle?: InputMaybe<Scalars['subtitle_String_maxLength_255']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  variations?: InputMaybe<Array<VariationInput>>;
};

export type AddLabelInput = {
  name: Scalars['name_String_NotNull_minLength_1_maxLength_50'];
};

export type AddLabelResult = {
  __typename?: 'AddLabelResult';
  label: ItemLabel;
  message: Maybe<Scalars['String']>;
};

export type AddModifierInput = {
  description?: InputMaybe<Scalars['description_String_maxLength_1500']>;
  image?: InputMaybe<Scalars['image_String_maxLength_1024']>;
  name: Scalars['name_String_NotNull_minLength_1_maxLength_255'];
  options?: InputMaybe<Array<ModifierOptionInput>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type AddModifierResult = {
  __typename?: 'AddModifierResult';
  modifier: Maybe<Modifier>;
};

export enum AppEnum {
  Core = 'CORE',
  Site = 'SITE'
}

export type ApplySectionResult = {
  __typename?: 'ApplySectionResult';
  items: Maybe<Array<Item>>;
  message: Maybe<Scalars['String']>;
  modifiers: Maybe<Array<Modifier>>;
  settings: Scalars['JSONObject'];
};

export type Category = {
  __typename?: 'Category';
  children: Array<Category>;
  description: Maybe<Scalars['String']>;
  designId: Scalars['Int'];
  id: Scalars['Int'];
  items: Array<Item>;
  modifiers: Array<Modifier>;
  name: Scalars['String'];
  rank: Maybe<Scalars['Int']>;
};

export type CategoryConnectInput = {
  categoryId: Scalars['Int'];
};

export type CategoryCreateInput = {
  designId: Scalars['Int'];
  name: Scalars['String'];
};

export type CategoryInput = {
  connect?: InputMaybe<CategoryConnectInput>;
  create?: InputMaybe<CategoryCreateInput>;
};

export type ClaimAccountResult = {
  __typename?: 'ClaimAccountResult';
  message: Scalars['String'];
};

export type ClearDesignResult = {
  __typename?: 'ClearDesignResult';
  account: Account;
  design: Design;
  message: Maybe<Scalars['String']>;
};

export type CreateCheckoutSessionInput = {
  app?: InputMaybe<AppEnum>;
  cancelUrl: Scalars['String'];
  planCode: Scalars['String'];
  planInterval: PlanIntervalEnum;
  sourceButton?: InputMaybe<Scalars['String']>;
  sourceUrl?: InputMaybe<Scalars['String']>;
  successUrl: Scalars['String'];
};

export type CreateCheckoutSessionResult = {
  __typename?: 'CreateCheckoutSessionResult';
  account: Maybe<Account>;
  message: Scalars['String'];
  session: Maybe<StripeCheckoutSession>;
};

export type CuisineType = {
  __typename?: 'CuisineType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CurrentPlan = {
  __typename?: 'CurrentPlan';
  code: Scalars['String'];
  interval: Maybe<PlanIntervalEnum>;
  limitation: PlanLimit;
  price: Maybe<Scalars['Float']>;
};

export type DeleteAccountResult = {
  __typename?: 'DeleteAccountResult';
  message: Scalars['String'];
};

export type DeleteDesignResult = {
  __typename?: 'DeleteDesignResult';
  message: Maybe<Scalars['String']>;
};

export type DeleteFontResult = {
  __typename?: 'DeleteFontResult';
  message: Scalars['String'];
};

export type DeleteInvitationResult = {
  __typename?: 'DeleteInvitationResult';
  message: Scalars['String'];
};

export type DeleteItemResult = {
  __typename?: 'DeleteItemResult';
  message: Maybe<Scalars['String']>;
};

export type DeleteModifierResult = {
  __typename?: 'DeleteModifierResult';
  message: Maybe<Scalars['String']>;
};

export type Design = {
  __typename?: 'Design';
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  designSettings: Scalars['JSONObject'];
  elements: Scalars['String'];
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  path: Scalars['String'];
  showSymbol: Maybe<Scalars['Boolean']>;
  status: Maybe<DesignStatus>;
  theme: Maybe<Theme>;
  themeId: Maybe<Scalars['Int']>;
  thumbnail: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type DesignConnection = {
  __typename?: 'DesignConnection';
  edges: Array<DesignEdge>;
  pageInfo: Maybe<PageInfo>;
};

export type DesignEdge = {
  __typename?: 'DesignEdge';
  node: Design;
};

export enum DesignStatus {
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export type DesignThemeMapping = {
  designId: Scalars['Int'];
  themeId: Scalars['Int'];
};

export type DowngradeAccountInput = {
  designIds?: InputMaybe<Array<Scalars['Int']>>;
  invitationIds?: InputMaybe<Array<Scalars['Int']>>;
  itemIds?: InputMaybe<Array<Scalars['Int']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DowngradeAccountResult = {
  __typename?: 'DowngradeAccountResult';
  account: Account;
  message: Scalars['String'];
};

export type DuplicateDesignInput = {
  designId: Scalars['Int'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['name_String_NotNull_maxLength_50'];
  path: Scalars['path_String_NotNull_maxLength_50_pattern_09az_'];
};

export type DuplicateDesignResult = {
  __typename?: 'DuplicateDesignResult';
  design: Design;
  message: Maybe<Scalars['String']>;
};

export type EditAccountInput = {
  patch: EditAccountInputPatch;
};

export type EditAccountInputPatch = {
  about?: InputMaybe<Scalars['about_String_maxLength_1500']>;
  address?: InputMaybe<Scalars['address_String_maxLength_255']>;
  bookingUrl?: InputMaybe<Scalars['bookingUrl_String_maxLength_1024']>;
  city?: InputMaybe<Scalars['city_String_maxLength_255']>;
  country?: InputMaybe<Scalars['country_String_maxLength_255']>;
  countryCode?: InputMaybe<Scalars['countryCode_String_maxLength_255']>;
  cuisineTypeIds?: InputMaybe<Array<Scalars['Int']>>;
  currency?: InputMaybe<Scalars['currency_String_maxLength_20']>;
  customAddress?: InputMaybe<Scalars['customAddress_String_maxLength_255']>;
  email?: InputMaybe<Scalars['email_String_maxLength_320']>;
  latitude?: InputMaybe<Scalars['Float']>;
  logo?: InputMaybe<Scalars['logo_String_maxLength_1024']>;
  logoList?: InputMaybe<Array<Scalars['String']>>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['name_String_minLength_1_maxLength_50']>;
  note?: InputMaybe<Scalars['note_String_maxLength_255']>;
  openingHours?: InputMaybe<Array<OpeningHoursInput>>;
  orderLinks?: InputMaybe<Array<AccountLinkInput>>;
  phone?: InputMaybe<Scalars['phone_String_maxLength_20']>;
  postcode?: InputMaybe<Scalars['postcode_String_maxLength_255']>;
  restaurantTypeId?: InputMaybe<Scalars['Int']>;
  socialLinks?: InputMaybe<Array<AccountLinkInput>>;
  state?: InputMaybe<Scalars['state_String_maxLength_255']>;
  timezone?: InputMaybe<Scalars['timezone_String_maxLength_255']>;
  username?: InputMaybe<Scalars['username_String_maxLength_30_pattern_09az_']>;
  users?: InputMaybe<Array<UserInput>>;
  webSetting?: InputMaybe<AccountWebSettingInput>;
  website?: InputMaybe<Scalars['website_String_maxLength_1024']>;
};

export type EditAccountResult = {
  __typename?: 'EditAccountResult';
  account: Maybe<Account>;
};

export type EditDesignInfoInput = {
  id: Scalars['Int'];
  patch: EditDesignInfoInputPatch;
};

export type EditDesignInfoInputPatch = {
  description?: InputMaybe<Scalars['description_String_maxLength_1500']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['name_String_maxLength_255']>;
  path?: InputMaybe<Scalars['path_String_maxLength_50_pattern_09az_']>;
  themeId?: InputMaybe<Scalars['Int']>;
};

export type EditDesignResult = {
  __typename?: 'EditDesignResult';
  design: Design;
};

export type EditDesignSettingsInput = {
  id: Scalars['Int'];
  initItem?: InputMaybe<Scalars['Boolean']>;
  patch: EditDesignSettingsInputPatch;
};

export type EditDesignSettingsInputPatch = {
  designSettings?: InputMaybe<Scalars['JSONObject']>;
  elements?: InputMaybe<Scalars['String']>;
  showSymbol?: InputMaybe<Scalars['Boolean']>;
  themeId?: InputMaybe<Scalars['Int']>;
};

export type EditFacebookPageInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  syncFields: Scalars['String'];
};

export type EditItemInput = {
  id: Scalars['Int'];
  patch: EditItemInputPatch;
};

export type EditItemInputPatch = {
  category?: InputMaybe<CategoryInput>;
  description?: InputMaybe<Scalars['description_String_maxLength_1500']>;
  labelIds?: InputMaybe<Array<Scalars['Int']>>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  mainImage?: InputMaybe<Scalars['mainImage_String_maxLength_1024']>;
  modifierIds?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['name_String_maxLength_255']>;
  outOfStock?: InputMaybe<Scalars['Boolean']>;
  subImages?: InputMaybe<Array<Scalars['String']>>;
  subtitle?: InputMaybe<Scalars['subtitle_String_maxLength_255']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  variations?: InputMaybe<Array<VariationInput>>;
};

export type EditModifierInput = {
  id: Scalars['Int'];
  patch: EditModifierInputPatch;
};

export type EditModifierInputPatch = {
  description?: InputMaybe<Scalars['description_String_maxLength_1500']>;
  name?: InputMaybe<Scalars['name_String_maxLength_255']>;
  options?: InputMaybe<Array<ModifierOptionInput>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type EditModifierResult = {
  __typename?: 'EditModifierResult';
  modifier: Maybe<Modifier>;
};

export type Font = {
  __typename?: 'Font';
  accountId: Scalars['Int'];
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  info: Maybe<FontInfo>;
  name: Scalars['String'];
  url: Scalars['String'];
};

export type FontInfo = {
  __typename?: 'FontInfo';
  style: Maybe<Scalars['String']>;
  weight: Maybe<Scalars['String']>;
};

export type FontInfoInput = {
  style?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type GeneralResult = {
  __typename?: 'GeneralResult';
  message: Maybe<Scalars['String']>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  country: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  timeZone: Scalars['String'];
};

export type InitDesignAccountInput = {
  country?: InputMaybe<Scalars['country_String_maxLength_50']>;
  createStripeTrial?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['email_String_maxLength_320_format_email']>;
  name: Scalars['name_String_NotNull_maxLength_50'];
  restaurantTypeId?: InputMaybe<Scalars['Int']>;
};

export type InitDesignInput = {
  account: InitDesignAccountInput;
  menu?: InputMaybe<InitDesignMenuInput>;
  themeId?: InputMaybe<Scalars['Int']>;
};

export type InitDesignMenuInput = {
  name: Scalars['name_String_NotNull_maxLength_255'];
};

export type InitDesignResult = {
  __typename?: 'InitDesignResult';
  accountId: Scalars['Int'];
  designId: Scalars['Int'];
};

export type InviteUserInput = {
  email: Scalars['email_String_NotNull_maxLength_320_format_email'];
  role: AccountUserRoleInput;
};

export type InviteUserResult = {
  __typename?: 'InviteUserResult';
  invitation: Maybe<UserAccountInvitation>;
  message: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'Item';
  accountId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  labels: Array<ItemLabel>;
  mainImage: Maybe<Scalars['String']>;
  modifiers: Array<Modifier>;
  name: Scalars['String'];
  outOfStock: Scalars['Boolean'];
  status: Maybe<ItemStatus>;
  subImages: Array<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  updatedAt: Maybe<Scalars['DateTime']>;
  variations: Array<Variation>;
};

export type ItemConnection = {
  __typename?: 'ItemConnection';
  edges: Array<ItemEdge>;
  pageInfo: Maybe<PageInfo>;
};

export type ItemEdge = {
  __typename?: 'ItemEdge';
  node: Item;
};

export type ItemLabel = {
  __typename?: 'ItemLabel';
  category: Maybe<ItemLabelCategory>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum ItemLabelCategory {
  General = 'GENERAL',
  Spice = 'SPICE'
}

export enum ItemStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export type LayoutConnection = {
  __typename?: 'LayoutConnection';
  edges: Array<NodeLayoutEdge>;
};

export enum LayoutStatus {
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export type ManageItemResult = {
  __typename?: 'ManageItemResult';
  item: Item;
};

export type Menu = {
  __typename?: 'Menu';
  categories: Array<Category>;
  id: Scalars['Int'];
};

export type Modifier = {
  __typename?: 'Modifier';
  accountId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image: Maybe<Scalars['String']>;
  max: Maybe<Scalars['Int']>;
  min: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  options: Array<ModifierOption>;
  tags: Array<Tag>;
};

export type ModifierConnection = {
  __typename?: 'ModifierConnection';
  edges: Array<ModifierEdge>;
  pageInfo: Maybe<PageInfo>;
};

export type ModifierEdge = {
  __typename?: 'ModifierEdge';
  node: Modifier;
};

export type ModifierOption = {
  __typename?: 'ModifierOption';
  createdAt: Maybe<Scalars['Datetime']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  item: Maybe<Item>;
  kilojoules: Maybe<Scalars['Int']>;
  modifierId: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  price: Maybe<Scalars['Float']>;
  rank: Maybe<Scalars['Int']>;
  refId: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type ModifierOptionInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['Int']>;
  kilojoules?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  type: ModifierOptionType;
};

export enum ModifierOptionType {
  Item = 'ITEM',
  Modifier = 'MODIFIER'
}

export type Mutation = {
  __typename?: 'Mutation';
  addAccount: Maybe<AddAccountResult>;
  addDesign: Maybe<AddDesignResult>;
  addFonts: AddFontsResult;
  addItem: Maybe<ManageItemResult>;
  addLabel: Maybe<AddLabelResult>;
  addModifier: Maybe<AddModifierResult>;
  applySection: Maybe<ApplySectionResult>;
  archiveItem: Maybe<GeneralResult>;
  claimAccount: ClaimAccountResult;
  clearDesignData: Maybe<ClearDesignResult>;
  createCheckoutSession: CreateCheckoutSessionResult;
  deleteDesign: Maybe<DeleteDesignResult>;
  deleteFacebookPage: AccountFacebookPage;
  deleteFont: DeleteFontResult;
  deleteInvitation: Maybe<DeleteInvitationResult>;
  deleteItem: Maybe<DeleteItemResult>;
  deleteModifier: Maybe<DeleteModifierResult>;
  downgradeAccount: DowngradeAccountResult;
  duplicateDesign: Maybe<DuplicateDesignResult>;
  editAccount: Maybe<EditAccountResult>;
  editDesignInfo: Maybe<EditDesignResult>;
  editDesignSettings: Maybe<EditDesignResult>;
  editFacebookPage: AccountFacebookPage;
  editItem: Maybe<ManageItemResult>;
  editModifier: Maybe<EditModifierResult>;
  initDesign: Maybe<InitDesignResult>;
  inviteUser: Maybe<InviteUserResult>;
  publishDesign: Maybe<PublishedDesignResult>;
  restoreItem: Maybe<GeneralResult>;
  siteAddDesign: SiteAddDesignResult;
  siteDeleteDesign: SiteDeleteDesignResult;
  siteEditDesignInfo: Maybe<SiteEditDesignInfoResult>;
  siteEditDesignSettings: Maybe<SiteEditDesignResult>;
  siteInitDesign: Maybe<SiteInitDesignResult>;
  sitePublishDesign: Maybe<SitePublishedDesignResult>;
  unPublishDesign: Maybe<PublishedDesignResult>;
};


export type MutationAddAccountArgs = {
  input: AddAccountInput;
};


export type MutationAddDesignArgs = {
  input: AddDesignInput;
};


export type MutationAddFontsArgs = {
  input: AddFontsInput;
};


export type MutationAddItemArgs = {
  input: AddItemInput;
};


export type MutationAddLabelArgs = {
  input: AddLabelInput;
};


export type MutationAddModifierArgs = {
  input: AddModifierInput;
};


export type MutationApplySectionArgs = {
  sectionId: Scalars['Int'];
};


export type MutationArchiveItemArgs = {
  itemId: Scalars['Int'];
};


export type MutationClaimAccountArgs = {
  accountId: Scalars['Int'];
  claimToken: Scalars['String'];
  isSite?: InputMaybe<Scalars['Boolean']>;
};


export type MutationClearDesignDataArgs = {
  designId: Scalars['Int'];
};


export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput;
};


export type MutationDeleteDesignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFontArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteInvitationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteItemArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteModifierArgs = {
  id: Scalars['Int'];
};


export type MutationDowngradeAccountArgs = {
  input: DowngradeAccountInput;
};


export type MutationDuplicateDesignArgs = {
  input: DuplicateDesignInput;
};


export type MutationEditAccountArgs = {
  input: EditAccountInput;
};


export type MutationEditDesignInfoArgs = {
  input: EditDesignInfoInput;
};


export type MutationEditDesignSettingsArgs = {
  input: EditDesignSettingsInput;
};


export type MutationEditFacebookPageArgs = {
  input: EditFacebookPageInput;
};


export type MutationEditItemArgs = {
  input: EditItemInput;
};


export type MutationEditModifierArgs = {
  input: EditModifierInput;
};


export type MutationInitDesignArgs = {
  input: InitDesignInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationPublishDesignArgs = {
  input: PublishDesignInput;
};


export type MutationRestoreItemArgs = {
  itemId: Scalars['Int'];
};


export type MutationSiteAddDesignArgs = {
  input: SiteAddDesignInput;
};


export type MutationSiteEditDesignInfoArgs = {
  input: SiteEditDesignInfoInput;
};


export type MutationSiteEditDesignSettingsArgs = {
  input: SiteEditDesignSettingsInput;
};


export type MutationSiteInitDesignArgs = {
  input: SiteInitDesignInput;
};


export type MutationSitePublishDesignArgs = {
  input: SitePublishDesignInput;
};


export type MutationUnPublishDesignArgs = {
  id: Scalars['Int'];
};

export type NodeLayout = {
  __typename?: 'NodeLayout';
  id: Scalars['Int'];
  node: Scalars['String'];
  settings: Scalars['JSONObject'];
  status: Maybe<LayoutStatus>;
  thumbnail: Scalars['String'];
};

export type NodeLayoutEdge = {
  __typename?: 'NodeLayoutEdge';
  node: NodeLayout;
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  allDay: Scalars['Boolean'];
  dayOfWeek: Scalars['Int'];
  id: Scalars['Int'];
  times: Array<Scalars['JSONObject']>;
};

export type OpeningHoursInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  dayOfWeek?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  times?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']>>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type Plan = {
  __typename?: 'Plan';
  code: Scalars['String'];
  limitation: PlanLimit;
  prices: Maybe<Array<PlanPrice>>;
};

export enum PlanIntervalEnum {
  Month = 'month',
  Year = 'year'
}

export type PlanLimit = {
  __typename?: 'PlanLimit';
  DESIGN_TOOL_ACCESS: Maybe<Scalars['Boolean']>;
  IMAGE_ITEM: Scalars['Int'];
  ITEMS: Scalars['Int'];
  LIVE_MENU: Scalars['Int'];
  USERS: Scalars['Int'];
  WHITE_LABELLING: Maybe<Scalars['Boolean']>;
};

export type PlanPrice = {
  __typename?: 'PlanPrice';
  interval: PlanIntervalEnum;
  intervalCount: Scalars['Int'];
  price: Scalars['Float'];
  trialDays: Maybe<Scalars['Int']>;
};

export type PublishDesignInput = {
  designId: Scalars['Int'];
  name?: InputMaybe<Scalars['name_String_maxLength_255']>;
  path?: InputMaybe<Scalars['path_String_maxLength_50_pattern_09az_']>;
  thumbnail?: InputMaybe<Scalars['String']>;
};

export type PublishedDesignResult = {
  __typename?: 'PublishedDesignResult';
  design: Design;
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  UploadFontUrl: UploadUrlResult;
  UploadUrl: UploadUrlResult;
  account: Account;
  accountLabels: Array<ItemLabel>;
  accounts: Array<Account>;
  accountsWithPaging: AccountConnection;
  cuisineTypes: Array<CuisineType>;
  currentUser: Maybe<User>;
  design: Design;
  designs: DesignConnection;
  facebookPage: AccountFacebookPage;
  fonts: Maybe<Array<Font>>;
  geoLocation: Maybe<GeoLocation>;
  invitations: Array<UserAccountInvitation>;
  item: Item;
  items: ItemConnection;
  labels: Array<ItemLabel>;
  layouts: LayoutConnection;
  modifier: Modifier;
  modifierOptions: Array<ModifierOption>;
  modifiers: ModifierConnection;
  plans: Array<Plan>;
  restaurantTypes: Array<RestaurantType>;
  sections: SectionConnection;
  site: Maybe<SiteDesign>;
  siteThemes: Array<SiteTheme>;
  stripePortal: Maybe<StripePortal>;
  tag: Tag;
  tags: Array<Tag>;
  theme: Theme;
  themes: ThemeConnection;
};


export type QueryUploadFontUrlArgs = {
  accountId: Scalars['Int'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryUploadUrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryAccountArgs = {
  id: Scalars['Int'];
};


export type QueryAccountLabelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAccountsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAccountsWithPagingArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryCuisineTypesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryDesignArgs = {
  id: Scalars['Int'];
};


export type QueryDesignsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<DesignStatus>;
};


export type QueryItemArgs = {
  id: Scalars['Int'];
};


export type QueryItemsArgs = {
  excludeIds?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tagIds?: InputMaybe<Array<Scalars['Int']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryLabelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryModifierArgs = {
  id: Scalars['Int'];
};


export type QueryModifierOptionsArgs = {
  modifierId: Scalars['Int'];
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryModifiersArgs = {
  excludeIds?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  tagIds?: InputMaybe<Array<Scalars['Int']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRestaurantTypesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryStripePortalArgs = {
  returnUrl: Scalars['String'];
};


export type QueryTagArgs = {
  id: Scalars['Int'];
};


export type QueryTagsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
  keyword?: InputMaybe<Scalars['String']>;
};


export type QueryThemeArgs = {
  id: Scalars['Int'];
};


export type QueryThemesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RestaurantType = {
  __typename?: 'RestaurantType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Section = {
  __typename?: 'Section';
  fixtures: Scalars['JSONObject'];
  id: Scalars['Int'];
  settings: Scalars['JSONObject'];
  status: Maybe<LayoutStatus>;
  thumbnail: Scalars['String'];
  type: SectionType;
};

export type SectionConnection = {
  __typename?: 'SectionConnection';
  edges: Array<SectionEdge>;
};

export type SectionEdge = {
  __typename?: 'SectionEdge';
  node: Section;
};

export enum SectionType {
  Footer = 'footer',
  Header = 'header',
  Menu = 'menu'
}

export type SiteAddDesignInput = {
  themeId: Scalars['Int'];
};

export type SiteAddDesignResult = {
  __typename?: 'SiteAddDesignResult';
  message: Scalars['String'];
  siteDesign: SiteDesign;
};

export type SiteDeleteDesignResult = {
  __typename?: 'SiteDeleteDesignResult';
  message: Scalars['String'];
};

export type SiteDesign = {
  __typename?: 'SiteDesign';
  accountId: Scalars['ID'];
  colors: Scalars['JSONObject'];
  coverImage: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  designSettings: Scalars['JSONObject'];
  elements: Scalars['String'];
  fonts: Scalars['JSONObject'];
  links: Array<Scalars['JSONObject']>;
  orderLinks: Array<Scalars['JSONObject']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  styles: Scalars['JSONObject'];
  theme: SiteTheme;
  themeId: Maybe<Scalars['Int']>;
  thumbnail: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum SiteDesignStatus {
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export type SiteEditDesignInfoInput = {
  path?: InputMaybe<Scalars['path_String_maxLength_50_pattern_09az_']>;
  status?: InputMaybe<SiteDesignStatus>;
};

export type SiteEditDesignInfoResult = {
  __typename?: 'SiteEditDesignInfoResult';
  message: Scalars['String'];
  siteDesign: SiteDesign;
};

export type SiteEditDesignResult = {
  __typename?: 'SiteEditDesignResult';
  siteDesign: SiteDesign;
};

export type SiteEditDesignSettingsInputPatch = {
  designSettings?: InputMaybe<Scalars['JSONObject']>;
  elements?: InputMaybe<Scalars['String']>;
  themeId?: InputMaybe<Scalars['Int']>;
};

export type SiteInitDesignInput = {
  account: InitDesignAccountInput;
  themeId: Scalars['Int'];
};

export type SiteInitDesignResult = {
  __typename?: 'SiteInitDesignResult';
  accountId: Scalars['Int'];
};

export type SiteMigrateAllDesignsResult = {
  __typename?: 'SiteMigrateAllDesignsResult';
  message: Scalars['String'];
};

export type SitePublishDesignInput = {
  path?: InputMaybe<Scalars['path_String_maxLength_50_pattern_09az_']>;
};

export type SitePublishedDesignResult = {
  __typename?: 'SitePublishedDesignResult';
  message: Scalars['String'];
  siteDesign: SiteDesign;
};

export type SiteTheme = {
  __typename?: 'SiteTheme';
  designSettings: Scalars['JSONObject'];
  elements: Scalars['String'];
  elementsClean: Maybe<Scalars['String']>;
  fixtures: Scalars['JSONObject'];
  id: Scalars['Int'];
  image: Scalars['String'];
  name: Scalars['String'];
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  url: Maybe<Scalars['String']>;
};

export type StripePortal = {
  __typename?: 'StripePortal';
  url: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  accountId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Theme = {
  __typename?: 'Theme';
  categorySettings: Scalars['JSONObject'];
  designSettings: Scalars['JSONObject'];
  elements: Scalars['String'];
  fixtures: Scalars['JSONObject'];
  id: Scalars['Int'];
  image: Scalars['String'];
  isEmpty: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  isPopular: Scalars['Boolean'];
  name: Scalars['String'];
  previewUrl: Maybe<Scalars['String']>;
};

export type ThemeConnection = {
  __typename?: 'ThemeConnection';
  edges: Array<ThemeEdge>;
  pageInfo: Maybe<PageInfo>;
};

export type ThemeEdge = {
  __typename?: 'ThemeEdge';
  node: Theme;
};

export enum ThemeStatus {
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export type UploadUrlResult = {
  __typename?: 'UploadUrlResult';
  url: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  picture: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
  username: Maybe<Scalars['String']>;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  createdAt: Maybe<Scalars['DateTime']>;
  lastAccess: Maybe<Scalars['DateTime']>;
  role: Maybe<AccountUserRole>;
  user: Maybe<User>;
};

export type UserAccountInvitation = {
  __typename?: 'UserAccountInvitation';
  acceptedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  expiryDate: Maybe<Scalars['DateTime']>;
  id: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  status: UserAccountInvitationStatus;
};

export enum UserAccountInvitationStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING'
}

export type UserInput = {
  role: AccountUserRole;
  userId: Scalars['String'];
};

export type Variation = {
  __typename?: 'Variation';
  createdAt: Scalars['DateTime'];
  defaultOption: Scalars['Boolean'];
  id: Scalars['Int'];
  itemId: Scalars['Int'];
  kilojoules: Maybe<Scalars['Int']>;
  label: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Float']>;
};

export type VariationInput = {
  defaultOption?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  kilojoules?: InputMaybe<Scalars['kilojoules_Int_min_0_max_10000']>;
  label?: InputMaybe<Scalars['label_String_maxLength_255']>;
  price?: InputMaybe<Scalars['price_Float_min_0_max_100000000']>;
};

export type ExportDesignToThemeResult = {
  __typename?: 'exportDesignToThemeResult';
  designId: Scalars['Int'];
  themeId: Scalars['Int'];
};

export type SiteEditDesignSettingsInput = {
  patch: SiteEditDesignSettingsInputPatch;
};

export type GetAccountsWithPagingQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAccountsWithPagingQuery = { __typename?: 'Query', accountsWithPaging: { __typename?: 'AccountConnection', edges: Array<{ __typename?: 'AccountEdge', node: { __typename?: 'Account', id: number, name: string, logo: string | null, username: string, stripeCustomerId: string | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, total: number } | null } };

export type GetBillingPortalQueryVariables = Exact<{
  returnUrl: Scalars['String'];
}>;


export type GetBillingPortalQuery = { __typename?: 'Query', stripePortal: { __typename?: 'StripePortal', url: string | null } | null };


export const GetAccountsWithPagingDocument = gql`
    query GetAccountsWithPaging($limit: Int, $offset: Int) {
  accountsWithPaging(limit: $limit, offset: $offset) {
    edges {
      node {
        id
        name
        logo
        username
        stripeCustomerId
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      total
    }
  }
}
    `;

/**
 * __useGetAccountsWithPagingQuery__
 *
 * To run a query within a React component, call `useGetAccountsWithPagingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsWithPagingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsWithPagingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAccountsWithPagingQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsWithPagingQuery, GetAccountsWithPagingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsWithPagingQuery, GetAccountsWithPagingQueryVariables>(GetAccountsWithPagingDocument, options);
      }
export function useGetAccountsWithPagingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsWithPagingQuery, GetAccountsWithPagingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsWithPagingQuery, GetAccountsWithPagingQueryVariables>(GetAccountsWithPagingDocument, options);
        }
export type GetAccountsWithPagingQueryHookResult = ReturnType<typeof useGetAccountsWithPagingQuery>;
export type GetAccountsWithPagingLazyQueryHookResult = ReturnType<typeof useGetAccountsWithPagingLazyQuery>;
export type GetAccountsWithPagingQueryResult = Apollo.QueryResult<GetAccountsWithPagingQuery, GetAccountsWithPagingQueryVariables>;
export const GetBillingPortalDocument = gql`
    query GetBillingPortal($returnUrl: String!) {
  stripePortal(returnUrl: $returnUrl) {
    url
  }
}
    `;

/**
 * __useGetBillingPortalQuery__
 *
 * To run a query within a React component, call `useGetBillingPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingPortalQuery({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useGetBillingPortalQuery(baseOptions: Apollo.QueryHookOptions<GetBillingPortalQuery, GetBillingPortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingPortalQuery, GetBillingPortalQueryVariables>(GetBillingPortalDocument, options);
      }
export function useGetBillingPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingPortalQuery, GetBillingPortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingPortalQuery, GetBillingPortalQueryVariables>(GetBillingPortalDocument, options);
        }
export type GetBillingPortalQueryHookResult = ReturnType<typeof useGetBillingPortalQuery>;
export type GetBillingPortalLazyQueryHookResult = ReturnType<typeof useGetBillingPortalLazyQuery>;
export type GetBillingPortalQueryResult = Apollo.QueryResult<GetBillingPortalQuery, GetBillingPortalQueryVariables>;