import { AxiosError } from "axios";
import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { ApiResponse } from "../shared/types/network/api-response";
import { useSearchParams } from "react-router-dom";
import EmailIcon from "../common/icon/EmailIcon";
import PasswordIcon from "../common/icon/PasswordIcon";
import { authApi } from "../shared/services/auth/auth-api";
import VerifyEmailScreen from "./VerifyEmailScreen";
import { getRedirectUri } from "../shared/utils/url/get-redirect-uri";
import { getAppSource } from "../shared/utils/string/app-source";
import FormContainer from "../common/form/FormContainer";
import TextFormField from "../common/form/TextFormField";
import { InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowRightIcon from "../common/icon/ArrowRightIcon";

interface LoginFormValues {
    email: string;
    password: string;
}
export const authSchema = () =>
    Yup.object().shape({
        email: Yup.string()
            .required("Email is required.")
            .email("Email field must be a valid email."),
        password: Yup.string()
            .required("Password is required.")
            .min(8, "Password must be at least 8 characters.")
            .max(20, "Password may not be greater than 20 characters.")
            .matches(
                /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
                "Password must contain uppercase, lowercase letters and numbers."
            ),
    });

const LoginPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");

    const loginEmail = async ({ email, password }: LoginFormValues) => {
        setIsLoading(true);
        setErrorMessage("");
        try {
            await authApi.login(
                email,
                password,
                getAppSource(searchParams.get("redirect_uri"))
            );
            window.location.href = redirectUri || "https://menuzen.com";
        } catch (e: unknown) {
            const { response } = e as AxiosError<ApiResponse<string>>;
            if (response?.data?.message) {
                setErrorMessage(response?.data?.message);
            }
            setIsLoading(false);
        }
    };
    return (
        <Fragment>
            <div className="flex flex-col flex-auto justify-between mt-12 mb-12 md:justify-start md:flex-none">
                <FormContainer
                    className="flex flex-col flex-auto justify-between mt-12 mb-12 md:justify-start md:flex-none"
                    schema={authSchema()}
                    onSubmit={(v) => loginEmail(v as LoginFormValues)}
                >
                    <div className="pt-28 md:pt-0">
                        <p className="text-24 font-bold mt-40 leading-6 md:mt-12 md:text-20">
                            Success! Your password has been updated.
                        </p>
                        <p className="text-primary-80 mt-12">
                            Please log in below to get back to your account.
                        </p>
                        <div className="text-center min-h-20 text-error md:text-left md:mt-8">
                            {errorMessage}
                        </div>
                        <div className="mt-4">
                            <TextFormField
                                name="email"
                                type="email"
                                placeholder={"Email"}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="mt-16">
                            <TextFormField
                                name="password"
                                type="password"
                                placeholder={"Password"}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PasswordIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className="mt-16 md:mt-24">
                        <LoadingButton
                            variant="contained"
                            loading={isLoading}
                            type="submit"
                            fullWidth={true}
                        >
                            <p className={"flex items-center"}>
                                Log In
                                <span
                                    className={"text-8 ml-8 hidden md:inline"}
                                >
                                    <ArrowRightIcon />
                                </span>
                            </p>
                        </LoadingButton>
                    </div>
                </FormContainer>
            </div>
            <div />
        </Fragment>
    );
};

export default LoginPage;
