import React from "react";
function EmailIcon() {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 15 13"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.03502 1.22461H12.284C12.9886 1.22461 13.5651 1.80111 13.5651 2.50573V10.1924C13.5651 10.897 12.9886 11.4735 12.284 11.4735H2.03502C1.33041 11.4735 0.753906 10.897 0.753906 10.1924V2.50573C0.753906 1.80111 1.33041 1.22461 2.03502 1.22461Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5651 2.50488L7.15949 6.98879L0.753906 2.50488"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default EmailIcon;
