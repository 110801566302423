import { px2rem } from "./px2rem";
import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";

export const muiListItem: {
    defaultProps?: ComponentsProps["MuiListItem"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiListItem"];
    variants?: ComponentsVariants["MuiListItem"];
} = {
    styleOverrides: {
        root: {
            padding: `${px2rem(12)} 0`,
        },
    },
};

export const muiListItemText: {
    defaultProps?: ComponentsProps["MuiListItemText"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiListItemText"];
    variants?: ComponentsVariants["MuiListItemText"];
} = {
    styleOverrides: {
        primary: {
            fontSize: px2rem(16),
        },
    },
};

export const muiListItemButton: {
    defaultProps?: ComponentsProps["MuiListItemButton"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiListItemButton"];
    variants?: ComponentsVariants["MuiListItemButton"];
} = {
    styleOverrides: {
        root: {
            fontSize: px2rem(16),
            padding: `${px2rem(6)} 0`,
        },
    },
};
