import React, { FC } from "react";
import "./loading.scss";

export interface LoadingProps {
    size?: number;
}
const Loading: FC<LoadingProps> = ({ size = 80 }) => {
    return (
        <svg
            className="menuzen-loader"
            width={size}
            height={size}
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="menuzen-loader-bottom"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.5251 12.3532C26.8501 10.9572 28.8334 13.1695 24.4751 15.79C21.0918 17.8227 17.2084 18.7534 12.7751 18.4105C8.42509 18.0758 4.75009 17.2268 1.48342 14.7206C-0.408247 13.2756 -0.11658 11.8225 2.53342 12.4348C8.33342 13.7736 15.6668 13.8797 21.5251 12.3532Z"
                fill="#55D1D3"
            />
            <path
                className="menuzen-loader-middle"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3253 5.76519C22.4669 5.19375 23.5753 6.27133 21.7253 7.61832C17.4336 10.7286 12.5919 12.9654 6.06695 10.5898C3.75861 9.749 3.67528 7.77343 6.99195 7.62648C11.6253 7.41423 16.0419 6.89993 20.3253 5.76519Z"
                fill="#55D1D3"
            />
            <path
                className="menuzen-loader-top"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4499 0.728297C20.8166 1.55281 20.4082 4.01004 18.0749 4.33658C15.5749 4.68761 10.2833 4.41005 7.90825 3.06307C6.23325 2.1161 7.89992 1.05484 10.2583 0.916058C13.1083 0.760951 15.4332 0.230321 17.4499 0.728297Z"
                fill="#55D1D3"
            />
        </svg>
    );
};

export default Loading;
