import React, { useMemo, useState } from "react";
import { UserInfo } from "../shared/types/user/user-info";
import { useAsync } from "react-use";
import { authApi, UpdateUserInfo } from "../shared/services/auth/auth-api";
import { ZenImage } from "../common/image";
import { getFirstWord } from "../shared/utils/string/get-first-word";
import FacebookIcon from "../common/icon/FacebookIcon";
import { webAuth } from "../lib/auth";
import GoogleIcon from "../common/icon/GoogleIcon";
import EditIcon from "../common/icon/EditIcon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Connection } from "../shared/types/user/connection";
import ChangePasswordModal from "./profile/ChangePasswordModal";
import ChangeEmailModal from "./profile/ChangeEmailModal";
import ConfirmChangeEmailModal from "./profile/ConfirmChangeEmailModal";
import { getRedirectUri } from "../shared/utils/url/get-redirect-uri";
import { LoadingButton } from "@mui/lab";
import FormContainer from "../common/form/FormContainer";
import TextFormField from "../common/form/TextFormField";
import { Button, InputAdornment, TextField } from "@mui/material";
import EmailIcon from "../common/icon/EmailIcon";
import PasswordIcon from "../common/icon/PasswordIcon";
import AppLogo from "../layout/AppLogo";
import * as Yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Loading from "../components/loading/Loading";
import SwitchFormField from "../common/form/SwitchFormField";
import {
    getAppConfigs,
    getAppSource,
    SOURCE,
} from "../shared/utils/string/app-source";
import MenuboardBusiness from "./profile/MenuboardBusiness";
import CoreAppBusiness from "./profile/CoreAppBusiness";

export const updateProfileSchema = () =>
    Yup.object().shape({
        firstName: Yup.string()
            .max(50, "First name may not be greater than 50 characters.")
            .required("First name is required."),
        lastName: Yup.string()
            .max(50, "Last name may not be greater than 50 characters.")
            .required("Last name is required."),
    });

const MODAL = {
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_EMAIL: "CHANGE_EMAIL",
    CONFIRM_CHANGE_EMAIL: "CONFIRM_CHANGE_EMAIL",
};
interface ProfileValues extends FieldValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    settings: { newsletterEmail: boolean };
}

const ProfilePage = () => {
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [user, setUser] = useState<UserInfo>();
    const [isUnlinkFb, setIsUnlinkFb] = useState(false);
    const [modal, setModal] = useState<string | null>(null);
    const [isUnlinkGoogle, setIsUnlinkGoogle] = useState(false);
    const [newEmail, setNewEmail] = useState<string>("");
    const redirectUri = searchParams.get("redirect_uri");
    const formContext = useForm<ProfileValues>({
        mode: "onChange",
        reValidateMode: "onBlur",
        resolver: yupResolver(updateProfileSchema()),
    });
    useAsync(async () => {
        const res = await authApi.getProfile();
        formContext.reset(res.data);
        setUser(res.data);
    }, []);
    const configs = getAppConfigs(redirectUri);

    const fbProfile = useMemo(() => {
        return user?.identities.find((i) => i.provider === "facebook");
    }, [user]);

    const googleProfile = useMemo(() => {
        return user?.identities.find((i) => i.provider === "google-oauth2");
    }, [user]);

    const handleBack = () => {
        if (history.length > 1) {
            navigate(-1);
        } else {
            window.location.href = redirectUri || "https://menuzen.com";
        }
    };

    if (!user) {
        return (
            <div
                className={"h-full flex flex-auto items-center justify-center"}
            >
                <Loading />
            </div>
        );
    }
    const unLink = async (connection: Connection) => {
        if (connection === "facebook") {
            setIsUnlinkFb(true);
            fbProfile &&
                (await authApi.unlinkAccount(connection, fbProfile.id));
            const identities = user.identities.filter(
                (i) => i.provider !== "facebook"
            );
            setUser({ ...user, identities });
            setIsUnlinkFb(false);
        } else {
            setIsUnlinkGoogle(true);
            googleProfile &&
                (await authApi.unlinkAccount(connection, googleProfile.id));
            const identities = user.identities.filter(
                (i) => i.provider !== "google-oauth2"
            );
            setUser({ ...user, identities });
            setIsUnlinkGoogle(false);
        }
    };

    const link = (provider: string) => {
        const url = `${
            window.location.protocol +
            "//" +
            window.location.host +
            process.env.REACT_APP_LINK_ACCOUNT_CALLBACK
        }?connection=${provider}&redirect_uri=${window.encodeURIComponent(
            window.location.href
        )}`;
        webAuth.authorize({
            redirectUri: url,
            connection: provider === "google" ? "google-oauth2" : "facebook",
        });
    };

    const handleSubmit = async (v: UpdateUserInfo) => {
        setLoadingSubmit(true);
        await authApi.updateProfile(v);
        setLoadingSubmit(false);
    };

    const onChangePassword = async (password: string) => {
        await authApi.changePassword(password);
        setModal(null);
    };
    const handleChangeEmail = async (email: string) => {
        await authApi.changeEmail(email);
        setNewEmail(email);
        setModal(MODAL.CONFIRM_CHANGE_EMAIL);
    };

    const handleVerifyCode = async (code: string) => {
        await authApi.confirmChangeEmail(newEmail, parseInt(code, 10));
        setUser({ ...user, email: newEmail });
        setModal(null);
    };

    return (
        <div className={"flex flex-col min-h-screen bg-primary-10"}>
            <div
                className={
                    "flex flex-col flex-auto pt-40 px-24 max-w-1200 mx-auto w-full"
                }
            >
                <FormContainer
                    formContext={formContext}
                    defaultValues={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        settings: {
                            newsletterEmail: user.settings.newsletterEmail,
                        },
                    }}
                    schema={updateProfileSchema()}
                    onSubmit={handleSubmit}
                >
                    <div className="flex flex-col md:flex-row md:items-center">
                        <div
                            className="text-primary-70 mr-16"
                            onClick={handleBack}
                        >
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                stroke="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.2085 12.5H19.7918"
                                    strokeWidth="1.9"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5.2085 12.5L11.4585 18.75"
                                    strokeWidth="1.9"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5.2085 12.5L11.4585 6.25"
                                    strokeWidth="1.9"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>

                        <div className="flex items-center pt-20 flex-auto md:pt-0">
                            <p className={"flex-auto text-28 font-bold"}>
                                My Account
                            </p>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={loadingSubmit}
                            >
                                Save
                            </LoadingButton>
                        </div>
                    </div>
                    <div className="bg-white rounded-16 my-20 p-24 overflow-y-auto">
                        <div
                            className={
                                "flex items-center justify-center flex-col"
                            }
                        >
                            <div
                                className={`relative w-100 h-100 flex-shrink-0 overflow-hidden rounded-circle select-none ${
                                    user.picture ? "" : "bg-secondary-light"
                                }`}
                            >
                                <div
                                    className={
                                        "absolute top-0 left-0 right-0 bottom-0 w-100 h-100 object-cover object-center flex items-center justify-center"
                                    }
                                >
                                    {user.picture ? (
                                        <ZenImage
                                            src={user.picture}
                                            className={"object-contain h-full"}
                                        />
                                    ) : (
                                        <span
                                            className={
                                                "text-22 font-bold uppercase text-secondary"
                                            }
                                        >
                                            {getFirstWord(user.firstName) +
                                                getFirstWord(user.lastName)}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    "text-18 font-semibold mt-16 leading-none"
                                }
                            >
                                {user.firstName} {user.lastName}
                            </div>
                        </div>
                        <div
                            className={
                                "border border-primary-40 rounded-8 px-12 pt-8 pb-12 mt-24"
                            }
                        >
                            <p className={"text-14 font-semibold"}>Profile</p>
                            <div className={"text-primary-80 text-12 mt-6"}>
                                First Name
                            </div>
                            <div className="mt-8">
                                <TextFormField
                                    name="firstName"
                                    type="text"
                                    placeholder={"First Name"}
                                    fullWidth
                                />
                            </div>
                            <div className={"text-primary-80 text-12 mt-4"}>
                                Last Name
                            </div>
                            <div className="mt-8">
                                <TextFormField
                                    name="lastName"
                                    type="text"
                                    placeholder={"Last Name"}
                                    fullWidth
                                />
                            </div>
                        </div>

                        <div
                            className={
                                "border border-primary-40 rounded-8 px-12 pt-8 pb-12 mt-24"
                            }
                        >
                            <p className={"text-14 font-semibold"}>
                                Login Detail
                            </p>
                            <div className={"text-primary-80 text-12 mt-6"}>
                                Email
                            </div>
                            <div className={"flex items-center mt-8"}>
                                <TextFormField
                                    name="email"
                                    type="email"
                                    placeholder={"Email"}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span className={"text-12"}>
                                                    <EmailIcon />
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div
                                    className={
                                        "text-20 cursor-pointer p-8 ml-8"
                                    }
                                    onClick={() => setModal(MODAL.CHANGE_EMAIL)}
                                >
                                    <EditIcon />
                                </div>
                            </div>
                            <div className={"text-primary-80 text-12 mt-4"}>
                                Password
                            </div>
                            <div className={"flex items-center mt-8"}>
                                <TextField
                                    name="password"
                                    type="password"
                                    value="********"
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span className={"text-12"}>
                                                    <PasswordIcon />
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <div
                                    className={
                                        "text-20 cursor-pointer p-8 ml-8"
                                    }
                                    onClick={() =>
                                        setModal(MODAL.CHANGE_PASSWORD)
                                    }
                                >
                                    <EditIcon />
                                </div>
                            </div>

                            {configs.GOOGLE_LOGIN && (
                                <div
                                    className={
                                        "border border-primary-40 rounded-8 flex p-16 mt-8"
                                    }
                                >
                                    <div
                                        className={
                                            "border border-primary-40 w-45 h-45 rounded flex items-center justify-center text-24 shrink-0"
                                        }
                                    >
                                        <GoogleIcon />
                                    </div>
                                    <div className={"flex-auto px-10 min-w-0"}>
                                        <div className={""}>
                                            <p>Google</p>
                                            <p
                                                className={
                                                    "text-primary-70 text-10 truncate"
                                                }
                                            >
                                                {googleProfile
                                                    ? `${googleProfile.firstName} ${googleProfile.lastName}`
                                                    : "Not Connected"}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={"flex items-center shrink-0"}
                                    >
                                        <LoadingButton
                                            loading={isUnlinkGoogle}
                                            color={"primary15"}
                                            size="small"
                                            type="button"
                                            fullWidth={true}
                                            onClick={() =>
                                                googleProfile
                                                    ? unLink("google")
                                                    : link("google")
                                            }
                                        >
                                            <div className={"text-14 py-2"}>
                                                {googleProfile
                                                    ? "Disconnect"
                                                    : "Connect"}
                                            </div>
                                        </LoadingButton>
                                    </div>
                                </div>
                            )}

                            {configs.FACEBOOK_LOGIN && (
                                <div
                                    className={
                                        "border border-primary-40 rounded-8 flex p-16 mt-10"
                                    }
                                >
                                    <div
                                        className={
                                            "border border-primary-40 w-45 h-45 rounded flex items-center justify-center text-24 shrink-0"
                                        }
                                    >
                                        <FacebookIcon />
                                    </div>
                                    <div className={"flex-auto px-10 min-w-0"}>
                                        <div className={""}>
                                            <p>Facebook</p>
                                            <p
                                                className={
                                                    "text-primary-70 text-10 truncate"
                                                }
                                            >
                                                {fbProfile
                                                    ? `${fbProfile.firstName} ${fbProfile.lastName}`
                                                    : "Not Connected"}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={"flex items-center shrink-0"}
                                    >
                                        <LoadingButton
                                            color={"primary15"}
                                            loading={isUnlinkFb}
                                            size="small"
                                            type="button"
                                            fullWidth={true}
                                            onClick={() =>
                                                fbProfile
                                                    ? unLink("facebook")
                                                    : link("facebook")
                                            }
                                        >
                                            <div className={"text-14 py-2"}>
                                                {fbProfile
                                                    ? "Disconnect"
                                                    : "Connect"}
                                            </div>
                                        </LoadingButton>
                                    </div>
                                </div>
                            )}
                            {getAppSource(redirectUri) === SOURCE.BOARD ? (
                                <MenuboardBusiness />
                            ) : (
                                <CoreAppBusiness />
                            )}
                        </div>
                        <div
                            className={
                                "border border-primary-40 rounded-8 p-16 mt-24"
                            }
                        >
                            <div className={"flex items-start"}>
                                <div className={"flex-auto"}>
                                    <p className={"text-14 font-semibold"}>
                                        Menuzen Newsletter
                                    </p>
                                    <p className={"text-primary-70"}>
                                        Opt in to the Menuzen newsletter to
                                        receive the latest tips & tricks,
                                        product updates and special offers.
                                    </p>
                                </div>
                                <div className="flex-shrink-0">
                                    <SwitchFormField name="settings.newsletterEmail" />
                                </div>
                            </div>
                        </div>
                    </div>
                </FormContainer>
                <div className="mt-16">
                    <Button
                        fullWidth={true}
                        onClick={() =>
                            (window.location.href = `/logout?redirect_uri=${getRedirectUri(
                                redirectUri
                            )}`)
                        }
                    >
                        Sign Out
                    </Button>
                </div>
                <div className="flex items-center mt-28 mb-40">
                    <AppLogo />
                </div>
                <ChangePasswordModal
                    open={modal === MODAL.CHANGE_PASSWORD}
                    anchor={"bottom"}
                    showClose
                    onSubmit={onChangePassword}
                    onOpen={() => setModal(MODAL.CHANGE_PASSWORD)}
                    onClose={() => setModal(null)}
                />
                <ChangeEmailModal
                    open={modal === MODAL.CHANGE_EMAIL}
                    anchor={"bottom"}
                    showClose
                    email={user.email}
                    onSubmit={handleChangeEmail}
                    onOpen={() => setModal(MODAL.CHANGE_EMAIL)}
                    onClose={() => setModal(null)}
                />
                <ConfirmChangeEmailModal
                    open={modal === MODAL.CONFIRM_CHANGE_EMAIL}
                    anchor={"bottom"}
                    showClose
                    onBack={() => setModal(MODAL.CHANGE_EMAIL)}
                    onSubmit={handleVerifyCode}
                    onOpen={() => setModal(MODAL.CONFIRM_CHANGE_EMAIL)}
                    onClose={() => setModal(null)}
                />
            </div>
        </div>
    );
};

export default ProfilePage;
