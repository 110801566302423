import React from "react";

const CheckColorIcon = () => {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.1468 16.5321C45.0337 16.4859 49.8115 17.9773 53.8043 20.7952C57.7972 23.6132 60.8031 27.6151 62.3969 32.235C63.9971 36.9532 64.0652 42.0564 62.5916 46.8156C61.1179 51.5747 58.1778 55.7465 54.1913 58.7347C50.1394 61.7486 45.2307 63.3896 40.1808 63.4183C35.131 63.447 30.204 61.8621 26.118 58.8945C22.032 55.9269 19.0007 51.7318 17.4659 46.9207C15.9311 42.1097 15.9731 36.9342 17.5858 32.1486C19.2268 27.5041 22.2902 23.495 26.3405 20.6914C30.3908 17.8878 35.2219 16.4324 40.1468 16.5321V16.5321Z"
                fill="#E1F9F8"
            />
            <path
                d="M57.1799 58.4059C56.4543 58.6305 56.1606 58.3023 56.1779 58.1122C56.1952 57.9222 56.3679 57.6976 56.5234 57.5076C56.6648 57.373 56.7614 57.1981 56.7998 57.0067C56.7998 56.7821 56.7998 56.713 56.7998 56.1256C56.6616 55.1237 56.558 53.8453 56.4543 52.2906C56.1606 47.8337 55.9879 42.8412 55.9188 37.5897C55.9188 34.9639 55.9188 32.269 55.9188 29.5396C55.9188 28.1749 55.9188 26.8101 55.9188 25.4282V23.7007V23.597H55.2278H54.0531H51.6692H46.6595H36.1045H24.1848C24.1575 23.5856 24.1281 23.5797 24.0985 23.5797C24.0688 23.5797 24.0395 23.5856 24.0121 23.597C23.9969 23.6233 23.9889 23.6531 23.9889 23.6834C23.9889 23.7137 23.9969 23.7435 24.0121 23.7698V24.8063C24.0121 26.5337 24.0121 28.0885 24.0121 29.6432C24.0121 37.0196 24.0121 44.7588 24.0985 52.4461C24.0179 53.7693 24.0179 55.0961 24.0985 56.4193H28.8663L40.0086 56.5748C45.07 56.5354 50.1292 56.8007 55.1587 57.3694C58.51 58.3023 52.7748 58.8378 47.5923 59.2869C41.9607 59.5115 35.8972 59.6324 29.6955 59.667H23.5284C22.7944 59.5914 22.1099 59.2614 21.5936 58.7341C21.0597 58.1874 20.7514 57.4596 20.7299 56.6957V55.2792C20.7299 52.1351 20.7299 48.9738 20.7299 45.8471C20.7299 41.0965 20.7299 36.1386 20.7299 31.077C20.7299 28.4513 20.7299 25.8082 20.7299 23.1306C20.778 22.5499 20.9998 21.9971 21.3664 21.5441C21.7331 21.0912 22.2276 20.7592 22.7856 20.5912C23.2771 20.472 23.7835 20.4255 24.2885 20.453H29.3673H44.9147H52.6193H55.4697C55.9852 20.4279 56.5018 20.4685 57.0071 20.5739C57.5774 20.743 58.0872 21.0721 58.4761 21.5222C58.8649 21.9723 59.1165 22.5245 59.201 23.1133C59.2166 23.3953 59.2166 23.6778 59.201 23.9598V25.3591C59.201 31.5607 59.3219 40.1636 59.0628 45.0179C58.9419 48.2137 58.821 50.805 58.7346 52.9643C58.7346 54.0354 58.6655 55.0028 58.5273 55.8665C58.4943 56.3626 58.4133 56.8544 58.2855 57.3349C58.0695 57.8216 57.6732 58.2056 57.1799 58.4059Z"
                fill="#394252"
            />
            <path
                d="M50.7882 29.3672C51.1337 29.3672 51.5137 29.8682 51.531 30.1618C51.4692 30.7571 51.2351 31.3215 50.8573 31.7857C49.3449 34.0653 47.6523 36.22 45.7957 38.2292C43.4537 41.0165 41.3883 44.025 39.6286 47.2121L38.8167 48.6287L38.1948 49.7516L37.7974 50.4426C37.6592 50.6671 37.5383 50.9435 37.3828 51.0299C37.0962 51.2818 36.7964 51.5182 36.4846 51.7382C36.1076 51.8932 35.6951 51.9411 35.2926 51.8764C34.9245 51.8215 34.5879 51.6379 34.3425 51.3581C33.9843 51.1057 33.7032 50.7589 33.5305 50.3562C33.3233 49.9243 33.1332 49.4752 32.9259 49.0778L32.5459 48.3696L31.8549 47.2294C31.3712 46.4693 30.8702 45.7438 30.3865 45.0182C29.5129 43.9543 28.8239 42.7514 28.3481 41.4596C28.3481 40.164 29.8683 40.8895 31.1466 41.7015C32.1301 42.5483 33.0323 43.4852 33.8415 44.5C34.2778 45.0519 34.6815 45.6287 35.0507 46.2275C35.2408 46.5212 35.4308 46.8148 35.6035 47.143C35.7763 47.4713 35.6899 47.2812 35.6899 47.2985C35.6899 47.2985 35.6899 47.4713 35.7936 47.4713C35.8972 47.4713 35.7936 47.4713 35.9145 47.3676L36.1909 46.9357L36.7264 46.0375L38.9549 42.4443C40.4923 40.0258 42.0989 37.6246 43.7227 35.3788C44.7641 33.8636 46.0014 32.4928 47.4023 31.302C48.9398 30.1618 49.7517 29.3672 50.7882 29.3672Z"
                fill="#394252"
            />
        </svg>
    );
};

export default CheckColorIcon;
