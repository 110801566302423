import React, { FC, Fragment } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getRedirectUri } from "../../shared/utils/url/get-redirect-uri";
import { Button } from "@mui/material";

const ResetPasswordInvalid: FC = () => {
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");
    return (
        <Fragment>
            <div className="flex flex-col flex-auto justify-between mt-12 mb-12 md:justify-start md:flex-none">
                <div>
                    <p className="text-24 font-bold mt-40 leading-6 md:mt-12 md:text-20">
                        Oops. That link is invalid.
                    </p>
                    <p className="text-primary-80 mt-12">
                        Something went wrong. Looks like the link you clicked on
                        has expired. Click the button below to try resetting
                        your password again.
                    </p>
                </div>
                <div className="mt-16">
                    <Link
                        to={`/forgot-password?redirect_uri=${getRedirectUri(
                            redirectUri
                        )}`}
                        className="bg-info text-white py-12 block rounded-round w-200 text-center leading-20 mt-24"
                    >
                        <Button variant="contained" fullWidth={true}>
                            Try Again
                        </Button>
                    </Link>
                </div>
            </div>
            <div />
        </Fragment>
    );
};

export default ResetPasswordInvalid;
