import { ApiResponse } from "../../types/network/api-response";
import { deleteAPI, get, post, put } from "../api";
import { UserInfo } from "../../types/user/user-info";
import { Connection } from "../../types/user/connection";
import { SignupResponse } from "../../types/response/signup-response";
import { LoginResponse } from "../../types/response/login-response";
import { UserSetting } from "../../types/user/user-setting";

export interface UpdateUserInfo {
    firstName: string;
    lastName: string;
    settings: UserSetting;
}

export const authApi = {
    login: (
        email: string,
        password: string,
        source: string
    ): Promise<ApiResponse<LoginResponse>> => {
        return post(
            "/api/login",
            { email, password, source },
            { requestId: "auth.login" }
        );
    },

    loginViaToken: (
        token: string,
        source: string
    ): Promise<ApiResponse<string>> => {
        return post(
            "/api/login/token",
            { token, source },
            { requestId: "auth.login" }
        );
    },
    signUp: (
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        source: string,
        fromUrl?: string | null
    ): Promise<ApiResponse<SignupResponse>> => {
        return post(
            "/api/signup",
            { firstName, lastName, email, password, source, fromUrl },
            { requestId: "auth.signup", skipHandlers: [400] }
        );
    },
    getProfile: (): Promise<ApiResponse<UserInfo>> => {
        return get("/api/users", { requestId: "auth.get-profile" });
    },
    updateProfile: (data: UpdateUserInfo): Promise<ApiResponse<string>> => {
        return put("/api/users", data, { requestId: "auth.update-profile" });
    },
    sendVerification: (token: string): Promise<ApiResponse<string>> => {
        return post(
            "/api/send-verification-email",
            { token },
            {
                requestId: "auth.send-verification",
            }
        );
    },
    confirmVerification: (
        token: string,
        code: string
    ): Promise<ApiResponse<string>> => {
        return post(
            "/api/confirm-verification-email",
            { token, code },
            {
                requestId: "auth.confirm-verification",
            }
        );
    },
    linkAccount: (
        connection: Connection,
        accessToken: string
    ): Promise<ApiResponse<string>> => {
        return post(
            "/api/users/link",
            { connection, accessToken },
            {
                requestId: "auth.link-account",
            }
        );
    },
    unlinkAccount: (
        connection: Connection,
        secondaryId: string
    ): Promise<void> => {
        return deleteAPI(`/api/users/unlink/${connection}/${secondaryId}`, {
            requestId: "auth.unlink-account",
        });
    },
    changePassword: (newPassword: string): Promise<string> => {
        return put(
            "/api/users/password",
            { password: newPassword },
            {
                requestId: "auth.change-password",
            }
        );
    },
    changeEmail: (newEmail: string): Promise<string> => {
        return post(
            "/api/users/change-email",
            { email: newEmail },
            {
                requestId: "auth.change-email",
            }
        );
    },
    confirmChangeEmail: (email: string, code: number): Promise<string> => {
        return post(
            "/api/users/change-email",
            { email, code },
            {
                requestId: "auth.confirm-change-email",
            }
        );
    },
};
