import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";

export const muiCheckBox: {
    defaultProps?: ComponentsProps["MuiCheckbox"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiCheckbox"];
    variants?: ComponentsVariants["MuiCheckbox"];
} = {
    defaultProps: {
        color: "success",
    },
};
