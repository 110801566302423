import React, { FC } from "react";

const BarIcon: FC = () => {
    return (
        <svg
            className="svg-inline"
            viewBox="0 0 51 6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.9375" y="0.466797" width="50" height="5" rx="2.5" />
        </svg>
    );
};

export default BarIcon;
