import { ApiResponse } from "../../types/network/api-response";
import { get, post } from "../api";
import { ResetPasswordInfo } from "../../types/response/reset-password-info";

export const resetPasswordApi = {
    resetPassword: (
        email: string,
        redirectUri?: string | null
    ): Promise<ApiResponse<string>> => {
        return post(
            "/api/forgot-password",
            { email, redirectUri },
            { requestId: "forgot-password.reset" }
        );
    },
    getInfo: (token: string): Promise<ApiResponse<ResetPasswordInfo>> => {
        return get(`/api/reset-password/${token}`, {
            requestId: "forgot-password.get-info",
        });
    },

    setNewPassword: (
        token: string,
        password: string
    ): Promise<ApiResponse<ResetPasswordInfo>> => {
        return post(
            `/api/reset-password/${token}`,
            { password },
            { requestId: "forgot-password.set-password" }
        );
    },
};
