export const SOURCE = {
    SITE: "SITE",
    CORE: "CORE",
    HOME: "HOME",
    DIRECTLY: "DIRECTLY",
    UNKNOWN: "UNKNOWN",
    BOARD: "BOARD",
};
const APP_CONFIGS = {
    BOARD: {
        GOOGLE_LOGIN: true,
        FACEBOOK_LOGIN: false,
        ALLOW_REGISTER: false,
        MANAGE_BILLING: false,
    },
    DEFAULT: {
        GOOGLE_LOGIN: true,
        FACEBOOK_LOGIN: true,
        ALLOW_REGISTER: true,
        MANAGE_BILLING: true,
    },
};
export const getAppSource = (url?: string | null) => {
    if (!url) {
        return SOURCE.DIRECTLY;
    } else if (url.includes(process.env.REACT_APP_SOURCE_SITE as string)) {
        return SOURCE.SITE;
    } else if (url.includes(process.env.REACT_APP_SOURCE_APP as string)) {
        return SOURCE.CORE;
    } else if (url.includes(process.env.REACT_APP_SOURCE_BOARD as string)) {
        return SOURCE.BOARD;
    } else if (url.includes(process.env.REACT_APP_SOURCE_HOME as string)) {
        return SOURCE.HOME;
    } else {
        return SOURCE.UNKNOWN;
    }
};

export const getAppConfigs = (url?: string | null) => {
    const source = getAppSource(url);
    if (source === "BOARD") return APP_CONFIGS.BOARD;
    return APP_CONFIGS.DEFAULT;
};

export const getGetStartedUrl = (url?: string | null) => {
    if (getAppSource(url) === "site") {
        return `${process.env.REACT_APP_SOURCE_SITE}/c/select-theme`;
    } else {
        return `${process.env.REACT_APP_SOURCE_APP}/c/getstarted`;
    }
};
