import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { Switch } from "@mui/material";
import React from "react";

export interface SwitchElementProps<T extends FieldValues> {
    name: Path<T>;
    control?: Control<T>;
}

const SwitchFormField = <T extends FieldValues>({
    name,
    control,
}: SwitchElementProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Switch {...field} checked={!!field.value} />
            )}
        />
    );
};

export default SwitchFormField;
