import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { px2rem } from "./px2rem";
import { primaryColor, successColor } from "./palette";

export const muiOutlinedInput: {
    defaultProps?: ComponentsProps["MuiOutlinedInput"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiOutlinedInput"];
    variants?: ComponentsVariants["MuiOutlinedInput"];
} = {
    styleOverrides: {
        root: {
            color: primaryColor["70"],
            borderRadius: 6,
            "&.Mui-focused": {
                ".MuiOutlinedInput-notchedOutline": {
                    borderWidth: 1,
                    borderColor: successColor.main,
                },
            },
            "&:hover": {
                ".MuiOutlinedInput-notchedOutline": {
                    borderWidth: 1,
                    borderColor: successColor.main,
                },
            },
            "&.Mui-disabled": {
                background: primaryColor["40"],
                color: primaryColor["50"],

                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: primaryColor["40"],
                },
            },
            background: "white",
        },
        input: {
            padding: `${px2rem(6)} ${px2rem(12)}`,
            lineHeight: `${px2rem(28)}`,
            height: `${px2rem(28)}`,
            "&::placeholder": {
                color: primaryColor["70"],
                opacity: 1,
            },
        },
        notchedOutline: {
            borderColor: primaryColor["40"],
        },
        adornedStart: {
            paddingLeft: 0,
        },
        adornedEnd: {
            paddingRight: 0,
        },
        inputMultiline: {
            lineHeight: "initial",
            padding: 0,
        },
    },
};

export const muiInputAdornment: {
    defaultProps?: ComponentsProps["MuiInputAdornment"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiInputAdornment"];
    variants?: ComponentsVariants["MuiInputAdornment"];
} = {
    styleOverrides: {
        positionStart: {
            marginRight: 0,
            paddingLeft: px2rem(12),
        },
        positionEnd: {
            marginLeft: 0,
            paddingRight: px2rem(12),
        },
    },
};
