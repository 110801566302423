import React, { FC, Fragment, useState } from "react";
import { useParams } from "react-router";
import { ZenImage } from "../../common/image";
import { getFirstWord } from "../../shared/utils/string/get-first-word";
import { capitalizeFirstLetter } from "../../shared/utils/string/capitialize-first-letter";
import { inviteApi } from "../../shared/services/invite/invite-api";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";

interface AcceptInviteFormProps {
    accountId: number;
    logo: string;
    name: string;
    inviter: string;
    role: string;
}

const AcceptInviteForm: FC<AcceptInviteFormProps> = ({
    accountId,
    logo,
    name,
    inviter,
    role,
}) => {
    const { token = "" } = useParams();
    const { search } = useLocation();
    const [isAccepting, setIsAccepting] = useState(false);

    const handleAccept = async () => {
        setIsAccepting(true);
        const queries = new URLSearchParams(search);
        if (queries.get("source") === "board") {
            await inviteApi.acceptBoardInvite(token);
            window.location.href = `https://${process.env.REACT_APP_SOURCE_BOARD}/account/${accountId}`;
        } else {
            await inviteApi.acceptInvite(token);
            window.location.href = `https://${process.env.REACT_APP_SOURCE_APP}/c/account/${accountId}`;
        }
    };
    return (
        <Fragment>
            <div className={"flex-auto flex flex-col md:flex-none"}>
                <div className="text-center flex-auto">
                    <div
                        className={
                            "bg-white rounded-8 w-80 h-80 flex items-center justify-center logo-shadow mx-auto mt-40"
                        }
                    >
                        {logo ? (
                            <ZenImage
                                src={logo}
                                className={"object-contain h-full"}
                            />
                        ) : (
                            <span
                                className={
                                    "text-42 font-bold uppercase text-info"
                                }
                            >
                                {getFirstWord(name)}
                            </span>
                        )}
                    </div>
                    <p className="text-24 font-bold mt-20 leading-6 md:mt-12 md:text-20">
                        You&lsquo;ve been invited to join {name} on Menuzen.
                    </p>

                    <p className={"text-14 text-center text-primary-80 mt-12"}>
                        {inviter} has invited you to join {name} as a{" "}
                        {capitalizeFirstLetter(role.toLowerCase())}.
                    </p>
                </div>
                <div className={"mt-20"}>
                    <LoadingButton
                        variant="contained"
                        loading={isAccepting}
                        onClick={handleAccept}
                        fullWidth={true}
                    >
                        Accept
                    </LoadingButton>
                </div>
            </div>
            <div />
        </Fragment>
    );
};

export default AcceptInviteForm;
