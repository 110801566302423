import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";

export const muiNativeSelect: {
    defaultProps?: ComponentsProps["MuiNativeSelect"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiNativeSelect"];
    variants?: ComponentsVariants["MuiNativeSelect"];
} = {
    styleOverrides: {
        root: {},
    },
};
