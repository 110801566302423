import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { px2rem } from "./px2rem";

export const muiPaper: {
    defaultProps?: ComponentsProps["MuiPaper"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiPaper"];
    variants?: ComponentsVariants["MuiPaper"];
} = {
    defaultProps: {
        elevation: 0,
    },
    styleOverrides: {
        root: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            overflowY: "auto",
            maxWidth: px2rem(620),
            margin: "auto",
        },
    },
};
