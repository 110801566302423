import React, { FC, useMemo } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Box } from "@mui/material";
import shuffle from "lodash/shuffle";

import updatePlatformsImg from "../../assets/images/carousel/update-platforms.png";
import embedImg from "../../assets/images/carousel/embed.png";
import testimonial from "../../assets/images/carousel/testimonial.png";
import itemManagerImg from "../../assets/images/carousel/item-manager.png";
import Carousel from "../carousel/Carousel";

const SIGN_UP_ITEMS = [
    {
        title: "Don’t just take our word for it",
        image: testimonial,
    },
    {
        title: "Menus built for web and social media",
        description:
            "Boost menu views by 80% on average by showcasing your menu across key channels with Menuzen.",
        image: updatePlatformsImg,
    },
    {
        title: "Edit items fast with our Item Manager",
        description:
            "Making changes on the go? Quickly update item information via the dashboard.",
        image: itemManagerImg,
    },
    {
        title: "Embed your menu on your website",
        description:
            "Did you know you can embed your menu on your website? Just copy and paste the embed code from your published menu.",
        image: embedImg,
    },
];

const SignUpCarousel: FC = () => {
    const randomSignUpItems = useMemo(() => {
        return shuffle(SIGN_UP_ITEMS);
    }, []);

    return (
        <Carousel
            elements={randomSignUpItems.map((item, index) => (
                <div key={index} className="flex flex-col text-left">
                    <Box
                        className="font-bold"
                        sx={{ fontSize: "40px", lineHeight: "44px" }}
                    >
                        {item.title}
                    </Box>

                    {item.description && (
                        <Box mt="12px" sx={{ fontSize: "16px" }}>
                            {item.description}
                        </Box>
                    )}

                    <Box sx={{ mt: "42px" }}>
                        <img src={item.image} />
                    </Box>
                </div>
            ))}
        />
    );
};

export default SignUpCarousel;
