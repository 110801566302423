import { WebAuth } from "auth0-js";
import { getCookie } from "../shared/utils/cookie/get-cookie";

const authConfig = {
    domain: process.env.REACT_APP_AUTH_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    audience: "https://account.menuzen.com",
    scope: "openid profile email",
    responseType: "token",
};

export const webAuth = new WebAuth(authConfig);

export const isLoggedIn = () => {
    return !!getCookie("isLoggedIn");
};
