import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";

export const muiFormHelperText: {
    defaultProps?: ComponentsProps["MuiFormHelperText"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiFormHelperText"];
    variants?: ComponentsVariants["MuiFormHelperText"];
} = {
    styleOverrides: {
        root: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
};
