import { detect } from "detect-browser";
const browser = detect();

export const isSupportSNS = () => {
    return (
        browser &&
        [
            "chrome",
            "firefox",
            "edge",
            "edge-ios",
            "edge-chromium",
            "opera-mini",
            "opera",
            "safari",
            "ios",
            "crios", //ipad
            "android",
            "bb10",
            "ie",
        ].includes(browser.name)
    );
};
