import React, { FC } from "react";
import { isLoggedIn } from "../lib/auth";
import { Outlet, useSearchParams } from "react-router-dom";

const PublicRoute: FC = () => {
    const [searchParams] = useSearchParams();
    const redirectUri =
        searchParams.get("redirect_uri") || "https://menuzen.com";
    if (isLoggedIn()) {
        window.location.href = redirectUri;
        return null;
    } else {
        return <Outlet />;
    }
};

export default PublicRoute;
