import {
    FieldValues,
    FormProvider,
    SubmitHandler,
    useForm,
    UseFormProps,
    UseFormReturn,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, { PropsWithChildren } from "react";
import * as Yup from "yup";

export interface FormContainerProps<T extends FieldValues>
    extends PropsWithChildren<UseFormProps<T>> {
    onSubmit: SubmitHandler<T>;
    schema: Yup.AnyObjectSchema;
    formContext?: UseFormReturn<T>;
    className?: string;
}

const FormContainer = <T extends FieldValues = FieldValues>({
    schema,
    children,
    onSubmit,
    className,
    formContext,
    ...useFormProps
}: PropsWithChildren<FormContainerProps<T>>) => {
    if (!formContext) {
        const methods = useForm<T>({
            mode: "onChange",
            reValidateMode: "onBlur",
            resolver: yupResolver(schema),
            ...useFormProps,
        });
        const { handleSubmit } = methods;
        return (
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={className}
                    noValidate
                >
                    {children}
                </form>
            </FormProvider>
        );
    } else {
        return (
            <FormProvider {...formContext}>
                <form
                    className={className}
                    onSubmit={formContext.handleSubmit(onSubmit)}
                    noValidate
                >
                    {children}
                </form>
            </FormProvider>
        );
    }
};

export default FormContainer;
