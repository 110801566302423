import React, { useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";

const VerifiedSuccessPage: FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const url =
            window.localStorage.getItem("redirectUri") || "https://menuzen.com";

        navigate(
            `/login?fromVerified=true&redirect_uri=${window.encodeURIComponent(
                url
            )}`,
            {
                replace: true,
            }
        );
    }, []);
    return <div />;
};

export default VerifiedSuccessPage;
