import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { px2rem } from "./px2rem";

export const muiIconButton: {
    defaultProps?: ComponentsProps["MuiIconButton"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiIconButton"];
    variants?: ComponentsVariants["MuiIconButton"];
} = {
    styleOverrides: {
        root: {
            padding: px2rem(8),
        },
        sizeSmall: {
            width: 34,
            height: 34,
        },
        sizeMedium: {
            width: 40,
            height: 40,
        },
        sizeLarge: {
            width: 44,
            height: 44,
        },
    },
};
