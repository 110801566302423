import "./image.scss";
import React, { FC } from "react";
import classNames from "classnames";

export interface ZenImageProps {
    className?: string;
    onClick?: () => void;
    src: string;
}

const ZenImage: FC<ZenImageProps> = ({ className, src, onClick }) => {
    const imgClassList = () => {
        return classNames(["zen-img", className]);
    };

    return (
        <img
            alt={"Zen"}
            onClick={onClick}
            className={imgClassList()}
            src={src}
        />
    );
};

export default ZenImage;
