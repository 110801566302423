import React, { useState } from "react";
import AppLogo from "../layout/AppLogo";
import ForgotPasswordForm from "./reset-password/ForgotPasswordForm";
import ForgotPasswordSuccess from "./reset-password/ForgotPasswordSuccess";
import { resetPasswordApi } from "../shared/services/reset-password/reset-password-api";
import { useSearchParams } from "react-router-dom";

const ForgotPasswordPage = () => {
    const [sentTo, setSentTo] = useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");

    const resetPassword = async (email: string) => {
        await resetPasswordApi.resetPassword(email, redirectUri);
        setSentTo(email);
    };
    return (
        <div
            className={
                "p-40 md:py-60 md:px-70 flex flex-auto flex-col justify-between"
            }
        >
            <div className="hidden items-center justify-center flex-shrink-0 md:justify-start md:flex">
                <AppLogo />
            </div>
            {sentTo ? (
                <ForgotPasswordSuccess
                    email={sentTo}
                    onReset={resetPassword}
                    onClose={() => setSentTo(null)}
                />
            ) : (
                <ForgotPasswordForm onReset={resetPassword} />
            )}
        </div>
    );
};

export default ForgotPasswordPage;
