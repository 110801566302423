import React, { FC, Fragment, useState } from "react";
import * as Yup from "yup";
import { Link, useSearchParams } from "react-router-dom";
import EmailIcon from "../../common/icon/EmailIcon";
import { getRedirectUri } from "../../shared/utils/url/get-redirect-uri";
import ArrowLeftIcon from "../../common/icon/ArrowLeftIcon";
import FormContainer from "../../common/form/FormContainer";
import TextFormField from "../../common/form/TextFormField";
import { InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const resetPasswordSchema = () =>
    Yup.object().shape({
        email: Yup.string()
            .required("Email is required.")
            .email("Email field must be a valid email."),
    });
export interface ForgotPasswordFormProps {
    onReset: (email: string) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ onReset }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");
    const handleReset = async (email: string) => {
        setIsLoading(true);
        try {
            await onReset(email);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };
    return (
        <Fragment>
            <FormContainer
                className="flex flex-col flex-auto justify-between mt-12 mb-12 md:justify-start md:flex-none"
                schema={resetPasswordSchema()}
                onSubmit={(v) => handleReset(v.email)}
            >
                <div>
                    <div className={"mt-12 text-16"}>
                        <Link
                            to={`/login?redirect_uri=${getRedirectUri(
                                redirectUri
                            )}`}
                        >
                            <ArrowLeftIcon />
                        </Link>
                    </div>
                    <p className="text-24 font-bold mt-40 leading-6 md:mt-12 md:text-20">
                        Forgot your password?
                    </p>
                    <p className="text-primary-80 mt-12">
                        Don’t worry! We can help you get back into your account.
                        Enter your email below and we’ll send you a link to
                        reset your password.
                    </p>
                    <div className="mt-20">
                        <TextFormField
                            name="email"
                            type="email"
                            placeholder={"Email"}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>

                <div className="mt-16 md:mt-12">
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={isLoading}
                        fullWidth={true}
                    >
                        Reset Password
                    </LoadingButton>
                </div>
            </FormContainer>
            <div />
        </Fragment>
    );
};

export default ForgotPasswordForm;
