import { px2rem } from "./px2rem";
import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";
import { primaryColor, successColor } from "./palette";

export const muiTabs: {
    defaultProps?: ComponentsProps["MuiTabs"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiTabs"];
    variants?: ComponentsVariants["MuiTabs"];
} = {
    styleOverrides: {
        root: {
            minHeight: "initial",
        },
        indicator: {
            backgroundColor: successColor.main,
        },
    },
};

export const muiTab: {
    defaultProps?: ComponentsProps["MuiTab"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiTab"];
    variants?: ComponentsVariants["MuiTab"];
} = {
    styleOverrides: {
        root: {
            textTransform: "none",
            fontSize: px2rem(16),
            fontWeight: 600,
            margin: px2rem(10),
            padding: 0,
            minHeight: "initial",
            lineHeight: "initial",
            minWidth: "auto",
            "&:first-of-type": {
                marginLeft: 0,
            },
            "&:last-of-type": {
                marginRight: 0,
            },
        },
        textColorPrimary: {
            color: primaryColor[50],
        },
    },
};
