import { useParams } from "react-router";
import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authApi } from "../shared/services/auth/auth-api";
import { useAsync, useQueue } from "react-use";
import { UserInfo } from "../shared/types/user/user-info";
import AppLogo from "../layout/AppLogo";
import { inviteApi } from "../shared/services/invite/invite-api";
import { InviteInfo } from "../shared/types/response/invite-info";
import AcceptInviteInvalid from "./accept-invite/AcceptInviteInvalid";
import AcceptInviteExpired from "./accept-invite/AcceptInviteExpired";
import AcceptInviteForm from "./accept-invite/AcceptInviteForm";
import { isLoggedIn } from "../lib/auth";
import { ApiResponse } from "../shared/types/network/api-response";
import Loading from "../components/loading/Loading";

const AcceptInvitePage = () => {
    const { token = "" } = useParams();
    const { search } = useLocation();
    const [profile, setProfile] = useState<UserInfo>();
    const [isLoading, setIsLoading] = useState(true);
    const [inviteInfo, setInviteInfo] = useState<InviteInfo>();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate(
                `/signup?redirect_uri=${window.encodeURIComponent(
                    window.location.href
                )}&fromInvite=${token}`
            );
        }
    }, []);
    useAsync(async () => {
        if (isLoggedIn()) {
            const queries = new URLSearchParams(search);
            try {
                const promises: Promise<ApiResponse<InviteInfo | UserInfo>>[] =
                    [
                        queries.get("source") === "board"
                            ? inviteApi.getBoardInviteInfo(token)
                            : inviteApi.getInviteInfo(token),
                    ];
                promises.push(authApi.getProfile());
                const [inviteInfoRes, profileRes] = await Promise.all(promises);
                setInviteInfo(inviteInfoRes.data as InviteInfo);
                if (isLoggedIn()) {
                    setProfile(profileRes.data as UserInfo);
                }
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        }
    }, [navigate, token]);

    const isInvalid = () => profile?.email !== inviteInfo?.email;
    return (
        <div
            className={
                "p-40 md:py-60 md:px-70 flex flex-auto flex-col md:justify-between max-w-550 mx-auto"
            }
        >
            <div className="items-center justify-center flex-shrink-0 md:justify-start flex mx-auto">
                <AppLogo />
            </div>
            {isLoading ? (
                <div
                    className={
                        "h-full flex flex-auto items-center justify-center"
                    }
                >
                    <Loading />
                </div>
            ) : inviteInfo && isInvalid() ? (
                <AcceptInviteInvalid email={inviteInfo?.email} />
            ) : inviteInfo && !isInvalid() ? (
                <AcceptInviteForm
                    accountId={inviteInfo.id}
                    inviter={inviteInfo.inviter}
                    name={inviteInfo.accountName}
                    logo={inviteInfo.accountLogo}
                    role={inviteInfo.role}
                />
            ) : (
                <AcceptInviteExpired />
            )}
        </div>
    );
};

export default memo(AcceptInvitePage);
