import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../components/loading/Loading";
import classNames from "classnames";
import { Avatar, Button } from "@mui/material";
import { stringAvatar } from "../../shared/utils/string/username";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import React, { useEffect, useState } from "react";
import {
    useGetAccountsWithPagingQuery,
    useGetBillingPortalLazyQuery,
} from "../../generated/types";
import { useSearchParams } from "react-router-dom";

const CoreAppBusiness = () => {
    const [searchParams] = useSearchParams();
    const accountId = parseInt(searchParams.get("accountId") + "", 10);
    const { data, loading, fetchMore } = useGetAccountsWithPagingQuery({
        variables: { limit: 10, offset: 0 },
    });
    const [accounts, setAccounts] = useState<
        {
            id: number;
            name: string;
            logo: string | null;
            username: string;
            stripeCustomerId: string | null;
        }[]
    >([]);
    const [hasMoreAccount, setHasMoreAccount] = useState(true);

    const [getBillingUrl, { loading: isBillingLoading }] =
        useGetBillingPortalLazyQuery();

    const handleGetBillingUrl = async (accountId: number) => {
        const { data } = await getBillingUrl({
            variables: {
                returnUrl: window.location.href,
            },
            context: {
                headers: {
                    account: accountId,
                },
            },
        });
        if (data?.stripePortal?.url) {
            window.location.href = data?.stripePortal?.url;
        }
    };
    useEffect(() => {
        const list = data?.accountsWithPaging.edges.map((edge) => edge.node);
        setAccounts(list || []);
        if (!data?.accountsWithPaging.pageInfo?.hasNextPage) {
            setHasMoreAccount(false);
        } else {
            setHasMoreAccount(true);
        }
    }, [data, loading]);
    const onLoadMore = async () => {
        const res = await fetchMore({
            variables: {
                offset: accounts.length,
                limit: 10,
            },
        });
        const list = accounts.concat(
            res.data.accountsWithPaging.edges.map((edge) => edge.node)
        );
        setAccounts(list);
        if (!res.data.accountsWithPaging.pageInfo?.hasNextPage) {
            setHasMoreAccount(false);
        }
    };
    return (
        <div
            className={
                "border border-primary-40 rounded-8 px-12 pt-8 pb-12 mt-24"
            }
        >
            <p className={"text-14 font-semibold"}>My Businesses</p>
            <div className={"max-h-500 overflow-y-auto"} id={"scrollableDiv"}>
                <InfiniteScroll
                    dataLength={accounts.length}
                    hasMore={hasMoreAccount}
                    next={onLoadMore}
                    scrollableTarget="scrollableDiv"
                    loader={
                        <div
                            className={"flex items-center justify-center my-12"}
                        >
                            <Loading size={50} />
                        </div>
                    }
                >
                    {accounts.map((account, idx) => (
                        <div
                            key={account.id}
                            className={classNames({
                                "flex items-center border-primary-40 py-12":
                                    true,
                                "border-t": idx !== 0,
                            })}
                        >
                            {account.logo ? (
                                <Avatar
                                    sx={{
                                        width: 46,
                                        height: 46,
                                    }}
                                    variant="rounded"
                                    alt="avatar"
                                    src={account.logo}
                                    className="border border-primary-20 flex-shrink-0"
                                />
                            ) : (
                                <Avatar
                                    sx={{
                                        width: 46,
                                        height: 46,
                                    }}
                                    variant="rounded"
                                    className="border border-primary-20 flex-shrink-0"
                                    {...stringAvatar(account.name)}
                                />
                            )}
                            <div className={"flex-1 mx-10"}>
                                <div className={"text-14 font-semibold"}>
                                    {account.name}
                                </div>
                                <span
                                    className={
                                        "bg-primary-10 p-4 font-semibold text-10 rounded"
                                    }
                                >
                                    Free Forever
                                </span>
                            </div>
                            <div className={"flex-shrink-0 flex items-center"}>
                                {account.id !== accountId && (
                                    <div
                                        className={
                                            "bg-primary-10 rounded-round px-10 py-6 text-12 mr-6"
                                        }
                                        onClick={() =>
                                            (window.location.href = `https://${process.env.REACT_APP_SOURCE_APP}/c/account/${account.id}`)
                                        }
                                    >
                                        Switch To
                                    </div>
                                )}
                                <div
                                    className={classNames({
                                        "bg-primary-10 rounded-round px-10 py-6 text-12 relative":
                                            true,
                                        "cursor-pointer":
                                            !!account.stripeCustomerId,
                                        "cursor-not-allowed text-primary-50":
                                            !account.stripeCustomerId,
                                    })}
                                    onClick={() =>
                                        !isBillingLoading &&
                                        account.stripeCustomerId &&
                                        handleGetBillingUrl(account.id)
                                    }
                                >
                                    Manage Billing
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
            <Button
                size={"small"}
                variant={"dashed"}
                color={"success"}
                fullWidth
                onClick={() =>
                    (window.location.href = `https://${process.env.REACT_APP_SOURCE_APP}/c/getstarted`)
                }
            >
                <span className={"text-12 mr-4"}>
                    <FontAwesomeIcon icon={faPlus} />
                </span>{" "}
                Create New Business
            </Button>
        </div>
    );
};
export default CoreAppBusiness;
