import { BoxProps, Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useInterval } from "react-use";
import "./carousel.css";

interface CarouselProps {
    elements: JSX.Element[];
    boxProps?: BoxProps;
}

const TIME_TRANSITION = 500;

const Carousel: FC<CarouselProps> = ({ elements, boxProps }) => {
    const [currentElementIndex, setCurrentElementIndex] = useState(0);
    const [startAnimation, setStartAnimation] = useState(false);

    const prevCurrentIndex =
        (currentElementIndex % elements.length) - 1 >= 0
            ? (currentElementIndex % elements.length) - 1
            : elements.length - 1;
    const currentIndex = currentElementIndex % elements.length;

    useInterval(() => {
        setStartAnimation(true);
        setCurrentElementIndex(currentElementIndex + 1);
    }, 4000);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (currentElementIndex !== 0) {
            timeout = setTimeout(() => {
                setStartAnimation(false);
            }, TIME_TRANSITION);
        }

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [currentElementIndex]);

    return (
        <Box width="100%" height="100%" {...boxProps}>
            <Box
                sx={{
                    opacity: 1,
                    ...(!!currentElementIndex && {
                        animation: `${
                            startAnimation
                                ? "prevElementAnimation"
                                : "currentElementAnimation"
                        } ${(TIME_TRANSITION + 100) / 1000}s ease-out`,
                    }),
                }}
            >
                {
                    elements[
                        startAnimation && currentElementIndex !== 0
                            ? prevCurrentIndex
                            : currentIndex
                    ]
                }
            </Box>

            {
                // Cache Img
                <Box display="none">{elements}</Box>
            }
        </Box>
    );
};

export default Carousel;
