import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";

export const muiRadio: {
    defaultProps?: ComponentsProps["MuiRadio"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiRadio"];
    variants?: ComponentsVariants["MuiRadio"];
} = {
    defaultProps: {
        color: "success",
    },
};
