import { createTheme } from "@mui/material";
import { muiButton, muiButtonBase, muiButtonGroup } from "./mui-button";
import { paletteConfig } from "./palette";
import { muiInputAdornment, muiOutlinedInput } from "./mui-outlined-input";
import { muiChip } from "./mui-chip";
import { muiDrawer } from "./mui-drawer";
import { muiDivider } from "./mui-divider";
import {
    muiListItem,
    muiListItemButton,
    muiListItemText,
} from "./mui-list-item";
import { muiTab, muiTabs } from "./mui-tabs";
import { muiCard } from "./mui-card";
import { muiPaper } from "./mui-paper";
import { Shadows } from "@mui/material/styles/shadows";
import { muiSwitch } from "./mui-switch";
import { muiNativeSelect } from "./mui-native-select";
import { muiRadio } from "./mui-radio";
import { muiCheckBox } from "./mui-check-box";
import { muiFormHelperText } from "./mui-form";
import { muiCircularProgress } from "./mui-circular-progress";
import { muiIconButton } from "./mui-icon-button";

export const themeConfig = createTheme({
    shadows: [...Array(25).fill("none")] as Shadows,
    shape: {
        borderRadius: 4,
    },
    palette: paletteConfig.palette,
    typography: {
        fontFamily: ["soleil", "Helvetica", "Arial", "sans-serif"].join(", "),
    },
    components: {
        MuiButtonBase: muiButtonBase,
        MuiButton: muiButton,
        MuiButtonGroup: muiButtonGroup,
        MuiOutlinedInput: muiOutlinedInput,
        MuiInputAdornment: muiInputAdornment,
        MuiChip: muiChip,
        MuiDrawer: muiDrawer,
        MuiDivider: muiDivider,
        MuiListItem: muiListItem,
        MuiListItemText: muiListItemText,
        MuiListItemButton: muiListItemButton,
        MuiTabs: muiTabs,
        MuiTab: muiTab,
        MuiCard: muiCard,
        MuiPaper: muiPaper,
        MuiSwitch: muiSwitch,
        MuiNativeSelect: muiNativeSelect,
        MuiRadio: muiRadio,
        MuiCheckbox: muiCheckBox,
        MuiFormHelperText: muiFormHelperText,
        MuiCircularProgress: muiCircularProgress,
        MuiIconButton: muiIconButton,
    },
});
