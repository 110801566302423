import React, { useEffect } from "react";
import AppLogo from "../layout/AppLogo";
import SignUpForm from "./signup/SignUpForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowLeftIcon from "../common/icon/ArrowLeftIcon";
import { getAppConfigs } from "../shared/utils/string/app-source";

const SignUpPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");
    const configs = getAppConfigs(redirectUri);
    useEffect(() => {
        if (redirectUri) {
            window.localStorage.setItem("redirectUri", redirectUri);
        }
        if (!configs.ALLOW_REGISTER) {
            navigate("/login?redirect_uri=" + redirectUri);
        }
    }, []);
    return (
        <div
            className={
                "p-40 md:py-60 md:px-70 flex flex-auto flex-col md:justify-between"
            }
        >
            <div>
                <div className={"text-16 md:hidden"}>
                    <span
                        className={"cursor-pointer"}
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeftIcon />
                    </span>
                </div>
                <div className="flex items-center justify-center flex-shrink-0 md:justify-start">
                    <AppLogo />
                </div>
            </div>
            <SignUpForm
                onSignUpSuccess={() => {
                    window.location.href = redirectUri || "https://menuzen.com";
                }}
            />
        </div>
    );
};

export default SignUpPage;
