import React, { useState } from "react";
import { useParams } from "react-router";
import ResetPasswordForm from "./reset-password/ResetPasswordForm";
import { resetPasswordApi } from "../shared/services/reset-password/reset-password-api";
import { useAsync } from "react-use";
import ResetPasswordInvalid from "./reset-password/ResetPasswordInvalid";
import AppLogo from "../layout/AppLogo";
import LoginFullPage from "./LoginFullPage";
import Loading from "../components/loading/Loading";

const SCREEN = {
    INVALID: "INVALID",
    SUCCESS: "SUCCESS",
    LOGIN: "LOGIN",
};

const ResetPasswordPage = () => {
    const { token } = useParams<string>();
    const [isLoading, setIsLoading] = useState(true);
    const [screen, setScreen] = useState<string>(SCREEN.INVALID);
    useAsync(async () => {
        if (token) {
            setIsLoading(true);
            try {
                await resetPasswordApi.getInfo(token);
                setScreen(SCREEN.SUCCESS);
            } catch (e) {}
            setIsLoading(false);
        }
    }, [token]);

    return (
        <div
            className={
                "p-40 md:py-60 md:px-70 flex flex-auto flex-col justify-between"
            }
        >
            <div className="hidden items-center justify-center flex-shrink-0 md:justify-start md:flex">
                <AppLogo />
            </div>
            {isLoading ? (
                <div
                    className={
                        "h-full flex flex-auto items-center justify-center"
                    }
                >
                    <Loading />
                </div>
            ) : screen === SCREEN.SUCCESS ? (
                <ResetPasswordForm onSuccess={() => setScreen(SCREEN.LOGIN)} />
            ) : screen === SCREEN.LOGIN ? (
                <LoginFullPage />
            ) : (
                <ResetPasswordInvalid />
            )}
        </div>
    );
};

export default ResetPasswordPage;
