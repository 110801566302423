import axios, { AxiosRequestConfig } from "axios";

export const get = <T>(
    url: string,
    config?: AxiosRequestConfig
): Promise<T> => {
    return axios.get<T, T>(url, config);
};

export const put = <T>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig
): Promise<T> => {
    return axios.put<T, T>(url, data ?? {}, config);
};

export const post = <T>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig
): Promise<T> => {
    return axios.post<T, T>(url, data ?? {}, config);
};

export const deleteAPI = <T>(
    url: string,
    config?: AxiosRequestConfig
): Promise<T> => {
    return axios.delete<T, T>(url, config);
};
