import React, { FC } from "react";
import classNames from "classnames";

interface AgreeTermProps {
    className?: string;
}

const AgreeTerm: FC<AgreeTermProps> = ({ className }) => {
    return (
        <div
            className={classNames([
                "text-center mt-16 text-primary-70 text-10 flex-shrink-0 md:text-left",
                className,
            ])}
        >
            <p>By signing up, you agree to our</p>
            <p>
                <a
                    href={"https://www.menuzen.com/terms-conditions"}
                    target={"_blank"}
                    rel="noreferrer"
                    className="underline font-bold"
                >
                    Terms & Conditions
                </a>
                &nbsp;&&nbsp;
                <a
                    href={"https://www.menuzen.com/privacy-policy"}
                    target={"_blank"}
                    rel="noreferrer"
                    className="underline font-bold"
                >
                    Privacy Policy
                </a>
            </p>
        </div>
    );
};

export default AgreeTerm;
