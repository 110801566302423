import { ApiResponse } from "../../types/network/api-response";
import { get, post } from "../api";
import { InviteInfo } from "../../types/response/invite-info";

export const inviteApi = {
    getInviteInfo: (token: string): Promise<ApiResponse<InviteInfo>> => {
        return get(`/api/invites/${token}`, { requestId: "invite.get-info" });
    },
    acceptInvite: (token: string): Promise<ApiResponse<string>> => {
        return post(`/api/invites/${token}/accept`, null, {
            requestId: "invite.accept",
        });
    },
    getBoardInviteInfo: (token: string): Promise<ApiResponse<InviteInfo>> => {
        return get(`/api/invites/board/${token}`, {
            requestId: "invite.get-info",
        });
    },
    acceptBoardInvite: (token: string): Promise<ApiResponse<string>> => {
        return post(`/api/invites/board/${token}/accept`, null, {
            requestId: "invite.accept",
        });
    },
};
