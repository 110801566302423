import { useSearchParams } from "react-router-dom";
import { webAuth } from "../lib/auth";
import { authApi } from "../shared/services/auth/auth-api";
import { Connection } from "../shared/types/user/connection";
import React, { useEffect } from "react";
import Loading from "../components/loading/Loading";

const LinkCallbackPage = () => {
    const [searchParams] = useSearchParams();
    const redirectUri =
        searchParams.get("redirect_uri") || "https://menuzen.com";
    const connection = searchParams.get("connection");
    useEffect(() => {
        webAuth.parseHash(
            { hash: window.location.hash },
            async (err, result) => {
                if (err || !result) {
                    //window.alert("Something went wrong.");
                    //window.location.href = redirectUri;
                } else if (connection && result.accessToken) {
                    await authApi.linkAccount(
                        connection as Connection,
                        result.accessToken
                    );
                    // window.location.href = redirectUri;
                }
            }
        );
    }, []);
    return (
        <div
            className={
                "bg-gradient fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center"
            }
        >
            <Loading />
        </div>
    );
};

export default LinkCallbackPage;
