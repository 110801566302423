import { paletteConfig } from "./palette";
import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { Theme } from "@mui/material";
import { ComponentsVariants } from "@mui/material/styles/variants";

export const muiDivider: {
    defaultProps?: ComponentsProps["MuiDivider"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiDivider"];
    variants?: ComponentsVariants["MuiDivider"];
} = {
    styleOverrides: {
        root: {
            borderColor: paletteConfig.palette.divider,
        },
    },
};
