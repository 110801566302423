import {
    Control,
    Controller,
    FieldError,
    FieldValues,
    Path,
} from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

export interface TextFieldElementProps<T extends FieldValues>
    extends Omit<TextFieldProps, "name"> {
    name: Path<T>;
    parseError?: (error: FieldError) => string;
    control?: Control<T>;
}

const TextFieldElement = <T extends FieldValues>({
    parseError,
    type,
    required,
    name,
    control,
    ...rest
}: TextFieldElementProps<T>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { invalid, error },
            }) => {
                return (
                    <TextField
                        {...rest}
                        name={name}
                        value={value ?? ""}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={required}
                        type={type}
                        error={invalid}
                        helperText={
                            error
                                ? typeof parseError === "function"
                                    ? parseError(error)
                                    : error.message
                                : rest.helperText
                        }
                    />
                );
            }}
        />
    );
};

export default TextFieldElement;
