import React, { FC, useMemo } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Box } from "@mui/material";
import shuffle from "lodash/shuffle";

import currencySwitcherImg from "../../assets/images/carousel/currency-switcher.png";
import embedImg from "../../assets/images/carousel/embed.png";
import itemManagerImg from "../../assets/images/carousel/item-manager.png";
import newTemplateImg from "../../assets/images/carousel/new-templates.png";
import reorderImg from "../../assets/images/carousel/reorder.png";
import Carousel from "../carousel/Carousel";

const WhatNewBtn = (
    <Box
        sx={{
            backgroundColor: "#00CACE",
            px: "17px",
            py: "8px",
            borderRadius: "100px",
            width: "fit-content",
        }}
    >
        What&apos;s New
    </Box>
);
const DidYouKnowBtn = (
    <Box
        sx={{
            backgroundColor: "#9E0CAB",
            px: "17px",
            py: "8px",
            borderRadius: "100px",
            width: "fit-content",
        }}
    >
        Did You Know?
    </Box>
);

const LOGIN_ITEMS = [
    {
        title: "Currency switcher",
        description:
            "You can now use your local currency on your menu. Update it via the settings panel in the menu designer.",
        image: currencySwitcherImg,
        label: WhatNewBtn,
    },
    {
        title: "Edit items fast with our Item Manager",
        description:
            "Did you know you can quickly update item information via the dashboard?",
        image: itemManagerImg,
        label: DidYouKnowBtn,
    },
    {
        title: "New menu templates",
        description:
            "We have recently released a handful of new menu designs with more added monthly.",
        image: newTemplateImg,
        label: WhatNewBtn,
    },
    {
        title: "Drag and drop reordering",
        description:
            "To reorder items and categories on your menu, simply drag and drop within the item panel.",
        image: reorderImg,
        label: WhatNewBtn,
    },
    {
        title: "Embed your menu on your website",
        description:
            "Did you know you can embed your menu on your website? Just copy and paste the embed code from your published menu.",
        image: embedImg,
        label: DidYouKnowBtn,
    },
];

const LoginCarousel: FC = () => {
    const randomLoginItems = useMemo(() => {
        return shuffle(LOGIN_ITEMS);
    }, []);

    return (
        <Carousel
            elements={randomLoginItems.map((item, index) => (
                <div key={index} className="flex flex-col text-left">
                    <Box
                        mb="12px"
                        sx={{
                            color: "#fff",
                            fontWeight: 600,
                            fontSize: "18px",
                            lineHeight: "110%",
                        }}
                    >
                        {item.label}
                    </Box>
                    <Box
                        mb="12px"
                        className="font-bold"
                        sx={{ fontSize: "40px", lineHeight: "44px" }}
                    >
                        {item.title}
                    </Box>

                    <Box sx={{ fontSize: "16px" }}>{item.description}</Box>

                    <Box sx={{ mt: "42px" }}>
                        <img src={item.image} />
                    </Box>
                </div>
            ))}
        />
    );
};

export default LoginCarousel;
