import React, { FC, ReactElement } from "react";
import { isLoggedIn } from "../lib/auth";
import { Navigate, useSearchParams } from "react-router-dom";
import { getRedirectUri } from "../shared/utils/url/get-redirect-uri";

interface ProtectedRouteProps {
    redirectPath?: string;
    children: ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    redirectPath = "/login",
    children,
}) => {
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get("redirect_uri");
    if (!isLoggedIn()) {
        return (
            <Navigate
                to={`${redirectPath}?redirect_uri=${getRedirectUri(
                    redirectUri
                )}`}
                replace
            />
        );
    }

    return children;
};

export default ProtectedRoute;
