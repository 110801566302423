import { Canceler } from "axios";

interface Requests {
    [key: string]: Canceler;
}

export default class AxiosManager {
    private static instance: AxiosManager;
    private static debug: boolean;
    private requests: Requests = {};

    public static getInstance(debug?: boolean): AxiosManager {
        AxiosManager.debug = !!debug;
        return this.instance || new AxiosManager();
    }

    public addRequest(requestId: string, cancelFn: Canceler): void {
        AxiosManager.log(`adding request ${requestId}`);
        if (this.has(requestId)) {
            this.cancelRequest(
                requestId,
                `cancelRequest(${requestId}) from RequestManager.addRequest. Found duplicate pending request.`
            );
            this.requests[requestId] = cancelFn;
        } else {
            this.requests[requestId] = cancelFn;
        }
    }

    public removeRequest(requestId: string): void {
        AxiosManager.log(`removing request ${requestId}`);
        delete this.requests[requestId];
    }

    public cancelRequest(
        requestId: string,
        reason = `cancelRequest(${requestId}) from RequestManager.cancelRequest`
    ): void {
        AxiosManager.log(`cancelling request \`${requestId}\``);

        if (
            this.has(requestId) &&
            typeof this.requests[requestId] === "function"
        ) {
            this.requests[requestId](reason);
            this.removeRequest(requestId);

            AxiosManager.log(`request \`${requestId}\` cancelled`);
        }
    }

    public cancelAllRequests(reason: string): void {
        Object.keys(this.requests).forEach((requestId) => {
            const _reason =
                reason ||
                `cancelRequest(${requestId}) from RequestManager.cancelAllRequests`;
            this.cancelRequest(requestId, _reason);
        });
    }

    private has(requestId: string) {
        return this.requests[requestId];
    }

    private static log(message: string): void {
        if (AxiosManager.debug) {
            console.log(message);
        }
    }
}
