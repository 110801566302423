import { Theme } from "@mui/material";
import { ComponentsProps } from "@mui/material/styles/props";
import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { ComponentsVariants } from "@mui/material/styles/variants";
import {
    errorColor,
    primaryColor,
    secondaryColor,
    successColor,
} from "./palette";

export const muiButtonBase: {
    defaultProps?: ComponentsProps["MuiButtonBase"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiButtonBase"];
    variants?: ComponentsVariants["MuiButtonBase"];
} = {
    defaultProps: {
        disableRipple: true,
    },
};

export const muiButton: {
    defaultProps?: ComponentsProps["MuiButton"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiButton"];
    variants?: ComponentsVariants["MuiButton"];
} = {
    defaultProps: {
        disableElevation: true,
        variant: "contained",
    },
    styleOverrides: {
        root: () => ({
            borderRadius: 9999,
            textTransform: "none",
            "&:disabled": {
                background: primaryColor["40"],
                color: primaryColor["50"],
            },
        }),
        sizeSmall: {
            fontSize: 14,
            lineHeight: 1.5722,
            padding: "4px 18px",
        },
        sizeMedium: {
            fontSize: 16,
            lineHeight: 1.5,
            padding: "6px 22px",
        },
        sizeLarge: {
            fontSize: 18,
            lineHeight: 1.334,
            padding: "8px 22px",
        },
    },
    variants: [
        {
            props: { variant: "dashed" },
            style: {
                borderWidth: 1,
                borderStyle: "dashed",
                borderRadius: 4,
                justifyContent: "start",
                "&:hover": {
                    background: "initial",
                },
            },
        },
        {
            props: { variant: "dashed", color: "error" },
            style: {
                color: errorColor.main,
                borderColor: errorColor.main,
                "&:active": {
                    color: errorColor.light,
                    borderColor: errorColor.light,
                },
            },
        },
        {
            props: { variant: "dashed", color: "success" },
            style: {
                color: successColor.main,
                borderColor: successColor.main,
                "&:active": {
                    color: successColor.light,
                    borderColor: successColor.light,
                },
            },
        },
        {
            props: { variant: "dashed", color: "secondary" },
            style: {
                color: secondaryColor.main,
                borderColor: secondaryColor.main,
                "&:active": {
                    color: secondaryColor.light,
                    borderColor: secondaryColor.light,
                },
            },
        },
        {
            props: { variant: "dashed", color: "primary" },
            style: {
                color: primaryColor.main,
                borderColor: primaryColor.main,
                "&:active": {
                    color: primaryColor["50"],
                    borderColor: primaryColor["50"],
                },
            },
        },
        {
            props: { variant: "dashed", color: "primary50" },
            style: {
                color: primaryColor.main,
                borderColor: primaryColor["50"],
                "&:active": {
                    color: primaryColor["50"],
                    borderColor: primaryColor["50"],
                },
            },
        },
        {
            props: { variant: "outlined" },
            style: {
                borderWidth: 2,
                "&:hover": {
                    borderWidth: 2,
                    background: "initial",
                },
            },
        },
        {
            props: { variant: "outlined", color: "error" },
            style: {
                color: errorColor.main,
                borderColor: errorColor.main,
                "&:active": {
                    color: errorColor.light,
                    borderColor: errorColor.light,
                },
            },
        },
        {
            props: { variant: "outlined", color: "success" },
            style: {
                color: successColor.main,
                borderColor: successColor.main,
                "&:active": {
                    color: successColor.light,
                    borderColor: successColor.light,
                },
            },
        },
        {
            props: { variant: "outlined", color: "secondary" },
            style: {
                color: secondaryColor.main,
                borderColor: secondaryColor.main,
                "&:active": {
                    color: secondaryColor.light,
                    borderColor: secondaryColor.light,
                },
            },
        },
        {
            props: { variant: "outlined", color: "primary" },
            style: {
                color: primaryColor.main,
                borderColor: primaryColor.main,
                "&:active": {
                    color: primaryColor["50"],
                    borderColor: primaryColor["50"],
                },
            },
        },
        {
            props: { variant: "contained", size: "small" },
            style: {
                padding: "6px 20px",
            },
        },
        {
            props: { variant: "contained", size: "medium" },
            style: {
                padding: "8px 24px",
            },
        },
        {
            props: { variant: "contained", size: "large" },
            style: {
                padding: "10px 24px",
            },
        },
        {
            props: { variant: "contained", color: "error" },
            style: {
                "&:active": {
                    background: errorColor.light,
                },
            },
        },
        {
            props: { variant: "contained", color: "success" },
            style: {
                "&:active": {
                    background: successColor.light,
                },
            },
        },
        {
            props: { variant: "contained", color: "secondary" },
            style: {
                "&:active": {
                    background: secondaryColor.light,
                },
            },
        },
        {
            props: { variant: "contained", color: "primary" },
            style: {
                "&:active": {
                    background: primaryColor["50"],
                },
            },
        },
        {
            props: { color: "primary15" },
            style: {
                color: primaryColor.main,
                background: primaryColor["15"],
                "&:hover": {
                    color: primaryColor.main,
                    background: primaryColor["20"],
                },
                "&:active": {
                    color: primaryColor.main,
                    background: primaryColor["40"],
                },
            },
        },
    ],
};

export const muiButtonGroup: {
    defaultProps?: ComponentsProps["MuiButtonGroup"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiButtonGroup"];
    variants?: ComponentsVariants["MuiButtonGroup"];
} = {
    defaultProps: {
        disableRipple: true,
        disableElevation: true,
    },
};
