import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import AxiosManager from "../../utils/axios/axios-manager";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
const debug = process.env.NODE_ENV === "development";
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const { requestId } = config;
        if (requestId) {
            const source = axios.CancelToken.source();
            config.cancelToken = source.token;
            AxiosManager.getInstance(debug).addRequest(
                requestId,
                source.cancel
            );
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response: AxiosResponse) => {
        const { requestId } = response.config;
        if (requestId) {
            AxiosManager.getInstance(debug).removeRequest(requestId);
        }
        return response.data;
    },
    (error: AxiosError | undefined) => {
        //undefined in cancel request or offline
        if (error?.response) {
            const { config, status } = error?.response;
            const { requestId, skipHandlers = [] } = config;

            if (status && !skipHandlers.includes(status)) {
                //window.location.href = '/error';
            }
            if (requestId) {
                AxiosManager.getInstance(debug).removeRequest(requestId);
            }
        }
        return Promise.reject(error);
    }
);
