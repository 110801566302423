import React, { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { Box, Fade } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

import { getAppSource, SOURCE } from "../../shared/utils/string/app-source";
import LoginCarousel from "./LoginCarousel";
import SignUpCarousel from "./SignUpCarousel";

const PageLayout: FC = () => {
    const [viewPortHeight, setViewPortHeight] = useState<number>();
    const [searchParams] = useSearchParams();
    const source = getAppSource(searchParams.get("redirect_uri"));
    const { pathname } = useLocation();
    const isSignUpPage = pathname.startsWith("/signup");

    useEffect(() => {
        if (source === SOURCE.SITE) {
            document.body.classList.add("app-site");
        } else if (source === SOURCE.BOARD) {
            document.body.classList.add("app-board");
            document.title = "Menuboards.ai - Account";
        } else {
            document.body.classList.add("app-core");
        }
    }, []);

    useEffect(() => {
        const windowHeight = () => {
            setViewPortHeight(window.innerHeight);
        };
        window.addEventListener("resize", windowHeight);
        windowHeight();
        return () => {
            window.removeEventListener("resize", windowHeight);
        };
    }, []);
    return (
        <div>
            <div
                className={classNames(["h-screen flex flex-row flex-1"])}
                style={{
                    maxHeight: viewPortHeight ? `${viewPortHeight}px` : "auto",
                }}
            >
                <div className="h-screen md:max-h-full md:p-0 md:shadow-card w-full flex flex-col md:max-w-550">
                    <Box
                        sx={
                            ![SOURCE.BOARD, SOURCE.SITE].includes(source)
                                ? {
                                      lg: {
                                          backgroundImage: "none",
                                          backgroundColor: "white",
                                      },
                                  }
                                : {}
                        }
                        className="flex-auto flex flex-col items-stretch bg-gradient md:rounded-none md:h-full md:overflow-y-auto"
                    >
                        <Outlet />
                    </Box>
                </div>

                {![SOURCE.BOARD, SOURCE.SITE].includes(source) && (
                    <div className="hidden md:flex p-80 flex-1 items-center justify-center slide-container">
                        <TransitionGroup style={{ maxWidth: "740px" }}>
                            <Fade
                                key={`${isSignUpPage}`}
                                timeout={{
                                    appear: 0,
                                    enter: 700,
                                    exit: 0,
                                }}
                            >
                                <div>
                                    {isSignUpPage ? (
                                        <SignUpCarousel />
                                    ) : (
                                        <LoginCarousel />
                                    )}
                                </div>
                            </Fade>
                        </TransitionGroup>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PageLayout;
