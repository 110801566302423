import React, { FC, Fragment, useState } from "react";
import ArrowLeftIcon from "../../common/icon/ArrowLeftIcon";
import { LoadingButton } from "@mui/lab";
import { useIsWidthUp } from "../../common/hooks/is-width-up";
import Loading from "../../components/loading/Loading";

export interface ForgotPasswordSuccessProps {
    email: string;
    onReset: (email: string) => void;
    onClose: () => void;
}
const ForgotPasswordSuccess: FC<ForgotPasswordSuccessProps> = ({
    email,
    onReset,
    onClose,
}) => {
    const isMdUp = useIsWidthUp("md");
    const [isLoading, setIsLoading] = useState(false);

    const handleReset = async () => {
        setIsLoading(true);
        await onReset(email);
        setIsLoading(false);
    };

    return isLoading ? (
        <div className={"h-full flex flex-auto items-center justify-center"}>
            <Loading />
        </div>
    ) : (
        <Fragment>
            <div className="flex flex-col flex-auto justify-between mt-12 mb-12 md:justify-start md:flex-none">
                <div>
                    <div className={"mt-12 text-16"}>
                        <span className={"cursor-pointer"} onClick={onClose}>
                            <ArrowLeftIcon />
                        </span>
                    </div>
                    <p className="text-24 font-bold mt-40 leading-6 md:mt-12 md:text-20">
                        Check your email.
                    </p>
                    <p className="text-primary-80 mt-12">
                        We sent an email to{" "}
                        <span className="underline font-bold">{email}</span>.
                        Just follow the instructions in the email to reset your
                        password.
                    </p>
                </div>
                <div className="mt-16">
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={isLoading}
                        onClick={handleReset}
                        fullWidth={!isMdUp}
                    >
                        Don&#39;t see it? Resend
                    </LoadingButton>
                </div>
            </div>

            <div />
        </Fragment>
    );
};

export default ForgotPasswordSuccess;
