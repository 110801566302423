import omitDeep from "omit-deep-lodash";
import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    from,
    InMemoryCache,
    makeVar,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { getAppSource, SOURCE } from "../../shared/utils/string/app-source";

interface NetworkError {
    status: number | null;
}

const initNetworkError: NetworkError = {
    status: null,
};

export const networkErrorVar = makeVar<NetworkError>(initNetworkError);

const cache = new InMemoryCache({
    //addTypename: false,
    typePolicies: {
        Design: {
            keyFields: ["id"],
            fields: {
                categories: {
                    merge: false,
                },
            },
        },
        Query: {
            fields: {
                networkError: {
                    read() {
                        return networkErrorVar();
                    },
                },
            },
        },
        AccountFacebookPage: {
            keyFields: ["accountId"],
        },
        Account: {
            keyFields: ["id"],
        },
        Font: {
            keyFields: ["id"],
        },
        Item: {
            keyFields: ["id"],
        },
        Modifier: {
            keyFields: ["id"],
        },
    },
});

const middleware = new ApolloLink((operation, forward) => {
    operation.variables = omitDeep(operation.variables, "__typename");
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUri = urlParams.get("redirect_uri");
    if (getAppSource(redirectUri) === SOURCE.BOARD) {
        operation.setContext({
            uri: process.env.REACT_APP_API_BOARD_URL,
        });
    }

    return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        window.location.reload();
    }
    if (networkError) {
        const context = operation.getContext();
        const serverError = networkError as ServerError;
        if (
            [400, 404, 500].includes(serverError.statusCode) &&
            context.skipHandlers &&
            !context.skipHandlers.includes(serverError.statusCode)
        ) {
            // Example how to use skipHandlers
            // const { data: userData } = useGetCurrentUserQuery({ context: { skipHanlder: [400] } });
            networkErrorVar({ status: serverError.statusCode });
        }
    }
});

const link = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
    credentials: "include",
});

const client = new ApolloClient({
    cache,
    link: from([middleware, errorLink, link]),
});
export default client;
