import React from "react";
import { webAuth } from "../lib/auth";
import { useSearchParams } from "react-router-dom";
import { authApi } from "../shared/services/auth/auth-api";
import { getRedirectUri } from "../shared/utils/url/get-redirect-uri";
import { getAppSource } from "../shared/utils/string/app-source";
import Loading from "../components/loading/Loading";

const LoginCallbackPage = () => {
    const [searchParams] = useSearchParams();
    webAuth.parseHash({ hash: window.location.hash }, async (err, result) => {
        if (err || !result || !result.accessToken) {
            alert("Login error. Please try again!");
            window.location.href = `/login?redirect_uri=${getRedirectUri(
                searchParams.get("redirect_uri")
            )}`;
        } else {
            try {
                await authApi.loginViaToken(
                    result.accessToken,
                    getAppSource(searchParams.get("redirect_uri"))
                );
                window.location.href =
                    searchParams.get("redirect_uri") || "https://menuzen.com";
            } catch (e) {
                window.alert("Something went wrong!");
                window.location.href = `/login?redirect_uri=${getRedirectUri(
                    searchParams.get("redirect_uri")
                )}`;
            }
        }
    });
    return (
        <div className={"h-full flex items-center justify-center"}>
            <Loading />
        </div>
    );
};

export default LoginCallbackPage;
