import * as Yup from "yup";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import Dialog, { DialogProps } from "../../common/dialog/Dialog";
import { FieldValues, useForm } from "react-hook-form";
import FormContainer from "../../common/form/FormContainer";
import { Button, FormHelperText, Grid, TextField } from "@mui/material";
import { px2rem } from "../../common/theme/px2rem";
import { LoadingButton } from "@mui/lab";
import { AxiosError } from "axios";

const codeSchema = () =>
    Yup.object().shape({
        code: Yup.array().of(Yup.string().required()),
    });

interface ConfirmChangeEmailModalProps extends Omit<DialogProps, "onSubmit"> {
    onSubmit: (code: string) => void;
}

const ChangeEmailModal: FC<ConfirmChangeEmailModalProps> = ({
    onClose,
    onSubmit,
    ...props
}) => {
    const refs = useRef<HTMLInputElement[]>([]);

    const [isUpdating, setIsUpdating] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    useEffect(() => {
        if (!props.open) {
            setMessage(null);
        }
    }, [props.open]);
    const formContext = useForm();
    const handleSubmit = async (values: FieldValues) => {
        setIsUpdating(true);
        try {
            await onSubmit(values.code.join(""));
        } catch (e) {
            if (e instanceof AxiosError) {
                setMessage(e.response?.data.message);
            }
        }
        setIsUpdating(false);
    };
    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        idx: number
    ) => {
        setMessage(null);
        if (!isNaN(Number(e.target.value))) {
            if (parseInt(e.target.value, 10) < 10) {
                formContext.setValue(`code.${idx}`, e.target.value);
                if (idx < 5) {
                    refs.current[idx + 1].focus();
                }
            } else {
                const currentValue = formContext.getValues("code")[idx];
                const newValue = e.target.value;
                if (currentValue.length > 0) {
                    formContext.setValue(
                        `code.${idx}`,
                        newValue.replace(currentValue.toString(), "")
                    );
                    e.target.value = newValue.replace(
                        currentValue.toString(),
                        ""
                    );
                    if (idx < 5) {
                        refs.current[idx + 1].focus();
                    }
                }
            }
        }
    };
    const handlePaste = (e: React.ClipboardEvent, idx: number) => {
        setMessage(null);
        const str = e?.clipboardData?.getData("Text").trim();
        if (str) {
            const v = str.substring(0, 6 - idx);
            const reg = /^\d+$/;
            if (reg.test(v)) {
                v.toString()
                    .split("")
                    .forEach((i, index) => {
                        formContext.setValue(`code.${index + idx}`, i);
                        refs.current[index + idx].value = i;
                    });
                refs.current[Math.min(v.length + idx, 5)].focus();
            }
            e.preventDefault();
        }
    };
    return (
        <Dialog title={"Change Email"} {...props} onClose={onClose}>
            <FormContainer
                formContext={formContext}
                className="px-28 mt-20"
                schema={codeSchema()}
                onSubmit={handleSubmit}
            >
                <div className="flex flex-col items-center">
                    <div>
                        {Array.from(Array(6).keys()).map((idx) => (
                            <TextField
                                inputRef={(e) => {
                                    if (e) {
                                        refs.current[idx] = e;
                                    }
                                }}
                                key={idx}
                                name={`code.${idx}`}
                                type="number"
                                inputProps={{ style: { textAlign: "center" } }}
                                sx={{
                                    width: px2rem(45),
                                    height: px2rem(40),
                                    marginRight: px2rem(10),
                                }}
                                onChange={(e) => handleChange(e, idx)}
                                onPaste={(e) => handlePaste(e, idx)}
                            />
                        ))}
                    </div>
                    <FormHelperText error={true}>{message}</FormHelperText>
                </div>
                <div className="mt-28">
                    <Grid container spacing={1.5}>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                fullWidth
                                loading={isUpdating}
                            >
                                Confirm
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </div>
            </FormContainer>
        </Dialog>
    );
};

export default ChangeEmailModal;
